import axios from 'axios';
import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import ApiUrl from '../BaseUrl';
import withAuth from '../../../hooks/withAuth';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { changePassword } from '../ApiEndpoints';
import * as yup from 'yup';

const ChangePassword = () => {
	const topUpSchema = yup.object().shape({
		email: yup.string().required('Email is required').email('Invalid email format'),
		oldPassword: yup.string().required('Old Password is required'),
		newPassword: yup.string().required('New Password is required').min(6, 'Password must be at least 6 characters'),
		confirmPassword: yup.string().required('Confirm Password is required').oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm({
		resolver: yupResolver(topUpSchema),
	});

	const sendData = async (data) => {
		try {
			const id =localStorage.getItem('ambassadorID');
			const response = await axios.patch(`${changePassword}/${id}`, data);
			toast.success(response.data.message);
			reset();
		} catch (error) {
			if (error.response) {
				toast.error(error.response.data.message);
			} else {
				toast.error('An error occurred. Please try again later.');
			}
		}
	};

	return (
		<>
			<div>
				<section>
					<div className='container py-4'>
						<ToastContainer />
						<h4>Change Password</h4>
						<div className='row'>
							<div className='col-md-12'>
								<div className='tab-content border bg-white px-5 py-4 rounded' id='v-pills-tabContent'>
									<div className='tab-pane fade show active' id='v-pills-home' role='tabpanel' aria-labelledby='v-pills-home-tab'>
										<form onSubmit={handleSubmit(sendData)}>
											<div className='row g-3'>
												<div className='col-md-6'>
													<label>Email</label>
													<input
														type='email'
														name='email'
														className={`form-control-input ${errors.email ? 'is-invalid' : ''}`}
														aria-labelledby='passwordHelpInline'
														placeholder='Email'
														{...register('email')}
													/>
													{errors.email && <span className='invalid-feedback'>{errors.email.message}</span>}
												</div>
												<div className='col-md-6'>
													<label>Old Password</label>
													<input
														type='password'
														name='oldPassword'
														className={`form-control-input ${errors.oldPassword ? 'is-invalid' : ''}`}
														aria-labelledby='passwordHelpInline'
														placeholder='Old Password'
														{...register('oldPassword')}
													/>
													{errors.oldPassword && <span className='invalid-feedback'>{errors.oldPassword.message}</span>}
												</div>
												<div className='col-md-6'>
													<label>New Password</label>
													<input
														type='password'
														name='newPassword'
														className={`form-control-input ${errors.newPassword ? 'is-invalid' : ''}`}
														aria-labelledby='passwordHelpInline'
														placeholder='New Password'
														{...register('newPassword')}
													/>
													{errors.newPassword && <span className='invalid-feedback'>{errors.newPassword.message}</span>}
												</div>
												<div className='col-md-6'>
													<label>Confirm New Password</label>
													<input
														type='password'
														name='confirmPassword'
														className={`form-control-input ${errors.confirmPassword ? 'is-invalid' : ''}`}
														aria-labelledby='passwordHelpInline'
														placeholder='Confirm New Password'
														{...register('confirmPassword')}
													/>
													{errors.confirmPassword && <span className='invalid-feedback'>{errors.confirmPassword.message}</span>}
												</div>
											</div>

											<div className='row mt-3'>
												<div className='col-lg-6 col-md-12 py-3'>
													<button type='button' className='cancel-btn btn-lg'>
														Cancel
													</button>
												</div>
												<div className='col-lg-6 col-md-12 py-3'>
													<button type='submit' className='add-last-btn btn-lg'>
														Update
													</button>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
	);
};

export default withAuth(ChangePassword);
