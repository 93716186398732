import React, { useState, useEffect } from 'react'
import ApiUrl from '../BaseUrl';

const DetailSlider = ({ data, onDataChange }) => {

    const [mainImg, setMainImg] = useState(data ? data[0] : "")
    useEffect(() => {
        setMainImg(data ? data[0] : "")
    }, [onDataChange])

    const handleClick = (index) => {
        console.log(index);
        setMainImg(data ? data[index] : "");
    }
    return (
        <div className=''>
            <div className='border'>
                <div className='border m-1'>
                    <img src={`${ApiUrl}/images/${mainImg}`} width="100%" alt='' />
                </div>
            </div>
            <div className='d-flex gx-3 mt-2'>
                {
                    data && data?.map((da, i) =>
                        <div className='theme_slider'>
                            <img key={i} src={`${ApiUrl}/images/${da}`} alt='' width="100%" height="50" onClick={() => handleClick(i)} />
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default DetailSlider