import React, { useEffect, useState } from 'react';
import ApiUrl from '../BaseUrl';
import axios from 'axios';
import { toast } from 'react-toastify';
import withAuth from '../../../hooks/withAuth';
function SelectAmb() {
	const [Ambassador, setAmbassador] = useState();
	const [own, setOwn] = useState();
	const fn_ambassadorUpdate = (i) => {};
	return (
		<>
			<div className='container'>
				<h3 className='m-2'>Select Sub-Quality Controller</h3>
				<div className='row'>
					{Ambassador &&
						Ambassador?.map((i) => {
							if (i?.statusType === 'approved') {
								return (
									<div className='col-md-6 mt-3'>
										<div className='amd_data'>
											<div>
												<img
													src={`${ApiUrl}/images/${i?.profileImg}`}
													height={'140px'}
													alt='Amb_img'
												/>
											</div>
											<div style={{ fontSize: '0.9rem' }}>
												<b>ID :</b> {i._id}
												{(own?.[0]?.subAmbassadorIDs.filter(
													(i) => i?._id === i?._id
												)).length > 0 &&
												(i?.requestTojoin?.filter(
													(j) => j === localStorage.getItem('ambassadorID')
												)).length > 0 ? (
													<span
														style={{
															backgroundColor: 'green',
															color: 'white',
															padding: '0 0.4rem',
															borderRadius: '1rem',
															marginLeft: '4rem',
														}}
													>
														Accepted
													</span>
												) : i?.requestTojoin?.filter(
														(j) => j === localStorage.getItem('ambassadorID')
												  ).length > 0 ? (
													<span
														style={{
															backgroundColor: 'red',
															color: 'white',
															padding: '0 0.4rem',
															borderRadius: '1rem',
															marginLeft: '4rem',
														}}
													>
														Pending
													</span>
												) : (
													<span>ddd</span>
												)}
												{/* {i?.requestTojoin?.length === 0 ? (
                        <span></span>
                      ) : (i?.subAmbassador === false && (i?.requestTojoin?.filter(j => j === localStorage.getItem('ambassadorID'))).length > 0) ? (
                        <span style={{ backgroundColor: "red", color: "white", padding: "0 0.4rem", borderRadius: "1rem", marginLeft: "4rem" }}>Pending</span>
                      ) : (i?.subAmbassador === true
                        &&
                        (i?.requestTojoin?.filter(j => j === localStorage.getItem('ambassadorID'))).length > 0)
                        &&
                        (own[0]?.subAmbassadorIDs.filter(i => i?._id === i?._id)).length > 0
                        ? (
                          <span style={{ backgroundColor: "green", color: "white", padding: "0 0.4rem", borderRadius: "1rem", marginLeft: "4rem" }}>Accepted</span>
                        ) : ((i?.requestTojoin?.filter(j => j === localStorage.getItem('ambassadorID'))).length === 0) && (
                          <span></span>)
                      } */}
												<br />
												<b>Name :</b> {i.username}
												<br />
												<b>Contact No. :</b> {i.countryCode} {i.phoneNo}
												<br />
												<b>Office Address :</b> {i.address}
												<br />
												<b>City :</b> {i.city}
												<br />
												<b>Country :</b> {i.country}
												<br />
												{i?.requestTojoin?.length === 0 ? (
													<button
														className='btn btn-success btn-sm mt-2'
														width='100px'
														height='80px'
														onClick={(e) => {
															fn_ambassadorUpdate(i);
														}}
													>
														Send Request
													</button>
												) : (i?.requestTojoin?.filter(
														(j) => j === localStorage.getItem('ambassadorID')
												  )).length > 0 ? (
													<button
														disabled
														className='btn btn-success btn-sm mt-2'
														width='100px'
														height='80px'
														onClick={(e) => {
															fn_ambassadorUpdate(i);
														}}
													>
														Send Request
													</button>
												) : i?.subAmbassador === true &&
												  (i?.requestTojoin?.filter(
														(j) => j === localStorage.getItem('ambassadorID')
												  )).length > 0 ? (
													<button
														disabled
														className='btn btn-success btn-sm mt-2'
														width='100px'
														height='80px'
														onClick={(e) => {
															fn_ambassadorUpdate(i);
														}}
													>
														Send Request
													</button>
												) : (
													(i?.requestTojoin?.filter(
														(j) => j === localStorage.getItem('ambassadorID')
													)).length === 0 && (
														<button
															className='btn btn-success btn-sm mt-2'
															width='100px'
															height='80px'
															onClick={(e) => {
																fn_ambassadorUpdate(i);
															}}
														>
															Send Request
														</button>
													)
												)}
											</div>
										</div>
									</div>
								);
							}
						})}
				</div>
			</div>
		</>
	);
}

export default withAuth(SelectAmb);
