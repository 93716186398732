import React, { useState, useEffect, useRef } from 'react';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { AiFillCloseCircle } from 'react-icons/ai';
import ApiUrl from '../BaseUrl';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Radio } from 'antd';
import { MdLocationOn } from 'react-icons/md';
import withAuth from '../../../hooks/withAuth';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { allwarehouses, createRack, getAllRacks } from '../ApiEndpoints';
function MainStockRacks() {
	const [warehouseName,setWarehouseName]=useState('');
	const [racks, setRacks] = useState([]);
	const [typeOfRack, setTypeOfRack] = useState('owned');
	useEffect(() => {
		const id = localStorage.getItem('ambassadorID');
		console.log("t=============================id", id);
		async function getRacks() {
			await axios.get(`${getAllRacks}/${id}`).then((response) => {
				setRacks(response.data);
			})
		}
		getRacks();
		async function getAllWareHouses() {
			await axios.get(`${allwarehouses}/${id}`).then((response) => {
				setWareHouses(response.data);
			}).catch((err) => {
				console.log("===================================err", err);
			})
		}
		console.log("===================================warehouses", warehouses);
		getAllWareHouses();
		console.log("===========================", racks);
		console.log("===========================rack type is ", typeOfRack);

	}, [typeOfRack])
	const [formData, setFormData] = useState({
		name: '',
		numberOfRacks: '',
		rackType: '',
		rackSize: {
			front: { size: '', unit: '' },
			back: { size: '', unit: '' },
			height: { size: '', unit: '' }
		},
		type: 'rental',
		qualityController: ''
	});
	const handleChange = (e) => {
		const { name, value } = e.target;
		if (name.startsWith('front') || name.startsWith('back') || name.startsWith('height')) {
			const dimension = name.split(/(?=[A-Z])/)[0]; // Extract 'front', 'back', or 'height'
			const property = name.split(/(?=[A-Z])/)[1].toLowerCase(); // Extract 'size' or 'unit'
			setFormData(prevFormData => ({
				...prevFormData,
				rackSize: {
					...prevFormData.rackSize,
					[dimension]: {
						...prevFormData.rackSize[dimension],
						[property]: value
					}
				}
			}));
		} else {
			setFormData({
				...formData,
				[name]: value
			});
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			if (!formData.rackType) {
				return;
			}
			console.log("=====================", formData);
			// Send POST request with formatted form data
			const id = localStorage.getItem('ambassadorID');
			console.log("t=============================id", id);
			await axios.post(`${createRack}/${id}`, {
				name: warehouseName,
				numberOfRacks: formData.numberOfRacks,
				rackType: formData.rackType,
				rackSize: {
					front: {
						size: formData.rackSize.front.size,
						unit: formData.rackSize.front.unit
					},
					back: {
						size: formData.rackSize.back.size,
						unit: formData.rackSize.front.unit
					},
					height: {
						size: formData.rackSize.height.size,
						unit: formData.rackSize.height.unit
					}
				},
				type: typeOfRack,
				qualityController: id
			}).then(result => {
				console.log("===========================", result)
			}).catch(error => {
				console.log("i stuck here")
			})

			// Clear form after successful submission if needed
			setFormData({
				name: '',
				numberOfRacks: '',
				rackType: '',
				frontSize: '',
				frontUnit: '',
				backSize: '',
				backUnit: '',
				heightSize: '',
				heightUnit: '',
				type: '',
				qualityController: ''
			});

			// Handle success scenario (e.g., show success message)
		} catch (error) {
			// Handle error scenario (e.g., show error message)
			console.log(error);
		}
	};
	const { id } = useParams();

	const navigate = useNavigate();
	const [rackId, setRackId] = useState(null);
	const [stores, setStores] = useState([]);

	const [ambName, setAmbName] = useState('');
	const [sellerName, setSellerName] = useState('');
	const [storeName, setStoreName] = useState('');
	const [searchTerm, setSearchTerm] = useState('');
	const [unitSelect, setUnitSelect] = useState('ft');
	const [unitSearch, setUnitSearch] = useState('');
	const [rentUnit, setRentUnit] = useState('ft');
	const [storesid, setStoreId] = useState([]);
	const [productId, setProductID] = useState('');
	const [warehouses, setWareHouses] = useState(null);
	const [transferToValue, setTransferToValue] = useState('store');
	const [statestoreId, setStoresId] = useState('');
	const [buyRacks, setBuyRacks] = useState(null);
	const [statequantity, setQuantity] = useState('');
	const [rackUpdate, setRackUpdate] = useState();
	const uniqueObjectsById = {};
	const handleTransferTo = (e) => {
		setTransferToValue(e.target.value);
	};

	const handleCreateRack = (values) => { };

	const ownedRacks = racks?.filter((own) => own?.type === 'owned');
	const myRentalRacks = racks?.filter((own) => own?.type === 'rental');

	const filteredData = racks?.filter((item) => {
		if (searchTerm.length > 0) {
			return item?.name?.includes(searchTerm);
		} else {
			return item?.unit?.includes(unitSearch);
		}
	});

	const [racksArray, setRackArray] = useState([]);

	const handleRentRack = (e) => {
		if (e?.target?.checked) {
			console.log(racksArray);
			racksArray?.push(e.target?.value);
		} else {
			setRackArray(racksArray?.filter((racks) => racks !== e.target?.value));
		}
	};

	const [selectedRadios, setSelectedRadios] = useState([]);

	// const handleChange = (e) => {
	// 	if (e?.target.checked) {
	// 		selectedRadios.push(e?.target?.value);
	// 	} else if (e?.target?.checked === false) {
	// 		setSelectedRadios(
	// 			selectedRadios.filter((rack) => rack !== e?.target?.value)
	// 		);
	// 	}
	// };

	const handleSellRack = () => { };

	const updateSend = (values) => {
		if (values?.unit?.value?.length === 0) {
			toast.error('Select Unit');
		} else if (values?.front?.value?.length === 0) {
			toast.error('please Enter Rack Front Size');
		} else if (values?.back?.value?.length === 0) {
			toast.error('please Enter Rack Back Size');
		} else if (values?.height?.value?.length === 0) {
			toast.error('please Enter Rack Height');
		} else if (values?.rackType?.value?.length === 0) {
			toast.error('please Select Rack Type');
		} else if (values?.productCapacity?.value?.length === 0) {
			toast.error('please Enter Product Capacity');
		} else {
			const params = {
				id: rackId,
				unit: values.unit.value,
				front: values.front.value,
				back: values.back.value,
				height: values.height.value,
				rackType: values.rackType.value,
				productCapacity: values.productCapacity.value,
				hasDimensions: true,
			};
			console.log(params);
			axios
				.put(`${ApiUrl}/mainStockRack/updateById`, params)

				.then((res) => {
					console.log(res.data);
					toast.success('Update successfully!');
					setTimeout(() => {
						window.location.reload();
					}, 1000);
				});
		}
	};

	const [productTotalQuantity, setProductTotalQuantity] = useState();
	const [minusProductQty, setMinusProductQty] = useState();

	const [selectStoreRack, setSelectStoreRack] = useState([]);
	const [stockLocation, setStockLocation] = useState('');

	const [rId, setRId] = useState([]);
	const [racksDetail, setRacksDetail] = useState([]);
	const handleSelectRack = (e, rack) => {
		if (e?.target?.checked) {
			setRId((prev) => [
				...prev,
				{ rackId: rack._id, name: rack.name, qty: 0 },
			]);
		} else if (e?.target?.checked === false) {
			setRId(rId.filter((rack) => rack !== e?.target?.value));
		}
	};

	function fn_handleRacksData(e, rack) {
		const qty = e.target.value;
		const tempRacks = rId.slice();
		const itemIndex = tempRacks.findIndex((r) => r.rackId === rack.rackId);
		tempRacks[itemIndex].qty = qty;
		setRacksDetail(tempRacks);
	}

	const addTransferIn = (values) => {
		const paramsStore = {
			productID: productId,
			sellerID: id,
			storeID: statestoreId,
			racksDetail: racksDetail,
			transferPro: racksDetail.reduce((acc, i) => {
				return acc + parseInt(i?.qty);
			}, 0),
			totalPro: productTotalQuantity,
			remaining:
				productTotalQuantity -
				racksDetail.reduce((acc, i) => {
					return acc + parseInt(i?.qty);
				}, 0),
		};
		const paramsRental = {
			productId: productId,
			rackIds: racksArray,
		};
		console.log(transferToValue === 'store' ? paramsStore : paramsRental);
		// if (transferToValue === "store") {
		//   axios
		//     .patch(`${ApiUrl}/e-stock/transferToStore`, paramsStore)
		//     .then((res) => {
		//       if (res.data.status === true) {
		//         toast.success("Stock Updated Successfully");
		//       }
		//     })
		//     .catch((error) => {
		//       toast?.error(error?.response?.data?.error);
		//     });
		// } else {
		//   axios
		//     .patch(`${ApiUrl}/e-stock/transferToRentalRacks`, paramsRental)
		//     .then((res) => {
		//       if (res.data.status === true) {
		//         toast.success("Stock Updated Successfully");
		//       }
		//     })
		//     .catch((error) => {
		//       toast.error("Such Quantity product not Available in Rack");
		//     });
		// }
	};

	return (
		<>
			<div>
				<header>
					{/* Model Racks Start */}
					<div
						class='modal fade'
						id='exampleModall'
						tabindex='-1'
						aria-labelledby='exampleModalLabel'
						aria-hidden='true'
					>
						<div class='modal-dialog'>
							<div class='modal-content'>
								<div class='modal-header'>
									<h1
										class='modal-title fs-5'
										id='exampleModalLabel'
									>
										Transfer for Rent
									</h1>
									<button
										type='button'
										class='btn-close'
										data-bs-dismiss='modal'
										aria-label='Close'
									></button>
								</div>
								<div className='modal-body'>
									<div
										className='row'
										style={{ maxHeight: '300px', overflowY: 'scroll' }}
									>
										{racks.map((option, index) => {
											if (
												option?.forSell === false &&
												option?.hasDimensions === true
											) {
												return (
													<>
														<div class=' col-3'>
															<label
																htmlFor={`option${option._id}`}
																key={option._id}
																className='mx-2'
															>
																<input
																	type='checkbox'
																	id={`option${option._id}`}
																	value={option._id}
																	disabled={option.state !== 'empty'}
																	onChange={(e) => {
																		handleChange(e);
																	}}
																/>
																Rack {index + 1}
															</label>
														</div>
													</>
												);
											}
										})}

										<br />
										<p className='text-start mt-3 mb-1 fw-bold'>Select Unit</p>
										<Radio.Group
											defaultValue={'feet'}
											buttonStyle='solid'
											id='unit'
											className='text-start'
											onChange={(e) => setRentUnit(e?.target?.value)}
										>
											<Radio.Button value='feet'>Feet</Radio.Button>
											<Radio.Button value='inch'>Inch</Radio.Button>
											<Radio.Button value='meter'>Meter</Radio.Button>
										</Radio.Group>
										<p className='text-start mt-3 mb-1 fw-bold'>
											Rack Front ({rentUnit})
										</p>
										<input
											type='text'
											id='sellFront'
											className='form-control'
											placeholder='Enter Rack Width'
										/>

										<p className='text-start mt-3 mb-1 fw-bold'>
											Rack Back ({rentUnit})
										</p>
										<input
											type='text'
											id='sellBack'
											className='form-control'
											placeholder='Enter Rack Width'
										/>

										<p className='text-start mt-3 mb-1 fw-bold'>
											Rack Height ({rentUnit})
										</p>
										<input
											type='text'
											id='sellHeight'
											className='form-control'
											placeholder='Enter Rack Hight'
										/>

										<p className='text-start mt-3 mb-1 fw-bold'>
											Per Rack Price per month
										</p>
										<input
											type='text'
											id='sellPrice'
											className='form-control'
											placeholder='Enter Unit Rack Price / Month'
										/>

										<p className='text-start mt-3 mb-1 fw-bold'>Rack Image</p>
										<input
											type='file'
											id='image'
											className='form-control'
											placeholder='Paste Image'
										/>
									</div>
								</div>
								<div class='modal-footer'>
									<button
										type='button'
										class='btn btn-secondary'
										data-bs-dismiss='modal'
									>
										Close
									</button>
									<button
										type='button'
										class='btn btn-success'
										data-bs-dismiss='modal'
										onClick={handleSellRack}
										style={{ backgroundColor: '#ffd700', color: 'black' }}
									>
										Post to Rent
									</button>
								</div>
							</div>
						</div>
					</div>
					{/* Model Racks End */}
					{/* Create Racks Modal Start */}
					<div
						class='modal fade'
						id='createRacks'
						tabindex='-1'
						aria-labelledby='exampleModalLabel'
						aria-hidden='true'
					>
						<form
							onSubmit={(e) => {
								e.preventDefault();
								handleSubmit(e);
							}}
						>
							<div class='modal-dialog'>
								<div class='modal-content'>
									<div class='modal-header'>
										<h1
											class='modal-title fs-5'
											id='exampleModalLabel'
										>
											Create Racks
										</h1>
										<button
											type='button'
											class='btn-close'
											data-bs-dismiss='modal'
											aria-label='Close'
										></button>
									</div>
									<div className='modal-body text-start'>
										<span style={{ fontWeight: '600' }}>WareHouse Name :</span>
										<select
											onChange={(e) => {
												const selectedWarehouse = warehouses.find(warehouse => warehouse._id === e.target.value);
												setWarehouseName(selectedWarehouse ? selectedWarehouse.name : '');
												setProductID(e.target.value);
											}}
											name='name'
											id='name'
											className='form-control mt-1 mb-2'
										>
											<option value={''}>--select Ware House---</option>
											{warehouses?.map((item) => (
												<option key={item._id} value={item._id}>
													{item.name}
												</option>
											))}
										</select>
										<span style={{ fontWeight: '600' }}>
											Number of Racks to be Made:
										</span>
										<input
											type='number'
											name='numberOfRacks'
											className='form-control mt-1 mb-2'
											placeholder='Enter Quantity'
											value={formData.numberOfRacks}
											onChange={handleChange}
										/>
										<span style={{ fontWeight: '600' }}>Rack Type:</span>
										<br />
										<Radio.Group
											defaultValue={''}
											buttonStyle='solid'
											className='mx-1 mt-1 mb-2'

											name='rackType'
											onChange={handleChange}
										>
											<Radio.Button value='Floor Rack'>Floor Rack</Radio.Button>
											<Radio.Button value='Hanger Rack'>
												Hanger Rack
											</Radio.Button>
											<Radio.Button value='Shelf Rack'>Shelf Rack</Radio.Button>
										</Radio.Group>
										<br />
										<span style={{ fontWeight: '600' }}>Rack Size:</span>
										<br />
										<div className='row mb-1'>
											<div
												className='col-2'
												style={{ display: 'grid', placeItems: 'center' }}
											>
												<label>Front</label>
											</div>
											<div className='col-5'>
												<Radio.Group
													defaultValue={''}
													buttonStyle='solid'
													className='mx-1'
													name='frontUnit'
													onChange={handleChange}
												>
													<Radio.Button value='feet'>Feet</Radio.Button>
													<Radio.Button value='inch'>Inch</Radio.Button>
													<Radio.Button value='meter'>Meter</Radio.Button>
												</Radio.Group>
											</div>
											<div className='col-5'>
												<input
													placeholder='Enter Front Size'
													name='frontSize'
													style={{
														height: '2rem',
														borderRadius: '0.3rem',
														border: '1px solid #DEE2E6',
														paddingLeft: '0.5rem',
													}}
													value={formData.frontSize}
													onChange={handleChange}
												/>
											</div>
										</div>
										<div className='row mb-1'>
											<div
												className='col-2'
												style={{ display: 'grid', placeItems: 'center' }}
											>
												<label>Back</label>
											</div>
											<div className='col-5'>
												<Radio.Group
													defaultValue={''}
													buttonStyle='solid'
													className='mx-1'
													name='backUnit'
													onChange={handleChange}

												>
													<Radio.Button value='feet'>Feet</Radio.Button>
													<Radio.Button value='inch'>Inch</Radio.Button>
													<Radio.Button value='meter'>Meter</Radio.Button>
												</Radio.Group>
											</div>
											<div className='col-5'>
												<input
													placeholder='Enter Back Size'
													name='backSize'
													style={{
														height: '2rem',
														borderRadius: '0.3rem',
														border: '1px solid #DEE2E6',
														paddingLeft: '0.5rem',
													}}
													value={formData.backSize}
													onChange={handleChange}

												/>
											</div>
										</div>
										<div className='row mb-1'>
											<div
												className='col-2'
												style={{ display: 'grid', placeItems: 'center' }}
											>
												<label>Height</label>
											</div>
											<div className='col-5'>
												<Radio.Group
													defaultValue={''}
													buttonStyle='solid'
													className='mx-1'
													name='heightUnit'

													onChange={handleChange}
												>
													<Radio.Button value='feet'>Feet</Radio.Button>
													<Radio.Button value='inch'>Inch</Radio.Button>
													<Radio.Button value='meter'>Meter</Radio.Button>
												</Radio.Group>
											</div>
											<div className='col-5'>
												<input
													placeholder='Enter Height'
													name='heightSize'
													style={{
														height: '2rem',
														borderRadius: '0.3rem',
														border: '1px solid #DEE2E6',
														paddingLeft: '0.5rem',
													}}
													value={formData.heightSize}
													onChange={handleChange}
												/>
											</div>
										</div>
									</div>
									<div class='modal-footer'>
										<button
											type='button'
											class='btn btn-secondary'
											data-bs-dismiss='modal'
										>
											Close
										</button>
										<button
											type='submit'
											class='btn btn-success'
											data-bs-dismiss='modal'
											style={{ backgroundColor: '#ffd700', color: 'black' }}
										>
											Create Racks
										</button>
									</div>
								</div>
							</div>
						</form>
					</div>
					{/* Create Racks Modal End */}
					{/* Product Transfer Modal Start */}
					<div
						class='modal fade'
						id='exampleModal3'
						tabindex='-1'
						aria-labelledby='exampleModalLabel'
						aria-hidden='true'
					>
						<div class='modal-dialog'>
							<div class='modal-content'>
								<div class='modal-header'>
									<h5
										class='modal-title'
										id='exampleModalLabel'
									>
										Product Transfer
									</h5>
									<button
										type='button'
										class='btn-close'
										data-bs-dismiss='modal'
										aria-label='Close'
									></button>
								</div>
								<div class='modal-body'>
									<from>
										<div className='col-md-12 text-start mb-2'>
											<label>Product</label>
											<select
												onChange={(e) => setProductID(e.target.value)}
												name='productID'
												id='productId'
												class='form-control-input-2'
											>
												<option
													selected
													value={''}
												>
													--select product---
												</option>
												{warehouses?.map((item) => {
													return (
														<option
															value={item?._id}
															id={item?._id}
														>
															{item?.name}
														</option>
													);
												})}
											</select>
											{productTotalQuantity > 0 && (
												<p>
													<b>{productTotalQuantity}</b> Available Quantity in
													Main Stock
												</p>
											)}
										</div>
										<div className='col-md-12 text-start mb-2'>
											<label className='fw-bold'>Transfer to :</label>
											<br />
											<input
												type='radio'
												name='transferTo'
												defaultChecked
												onChange={handleTransferTo}
												value={'store'}
												className='mx-1'
											/>
											Store Racks
											<input
												type='radio'
												name='transferTo'
												onChange={handleTransferTo}
												value={'Rental Racks'}
												className='ms-5 me-1'
											/>
											Rental Racks
										</div>
										{transferToValue === 'store' ? (
											<div className='col-md-12 text-start'>
												<label>Store</label>
												<select
													name='storeID'
													onChange={(e) => setStoresId(e.target.value)}
													class='form-control-input-2'
												>
													<option
														selected
														value={''}
													>
														--select store name---
													</option>
													{storesid &&
														storesid?.map((item) => {
															return (
																<option value={item?._id}>{item?.name}</option>
															);
														})}
												</select>
											</div>
										) : (
											<div>
												{buyRacks &&
													buyRacks?.map((rackNumber) => (
														<>
															<input
																type='checkbox'
																value={rackNumber?._id}
																onChange={handleRentRack}
															/>
															<label className='me-5'>{rackNumber?.name}</label>
														</>
													))}
											</div>
										)}
										{selectStoreRack.length > 0 && stockLocation && (
											<>
												<p
													style={{
														fontSize: '1.05rem',
														textAlign: 'right',
														margin: '0.5rem 0',
													}}
												>
													<MdLocationOn />
													&nbsp;
													{stockLocation}
												</p>
											</>
										)}
										<div
											className='mt-3'
											style={{
												display: 'grid',
												gridTemplateColumns: '1fr 1fr',
											}}
										>
											{selectStoreRack.length > 0 ? (
												selectStoreRack?.map((rack) => {
													if (rack?.state === 'occupied') {
														return (
															<div className='text-start mb-2'>
																<input
																	type='checkbox'
																	disabled
																/>
																<label>
																	<b style={{ color: 'grey' }}>{rack?.name}</b>{' '}
																	<span style={{ fontSize: '12px' }}>
																		(F : {rack?.front}, B : {rack?.back}, H :{' '}
																		{rack?.height})
																	</span>
																</label>
															</div>
														);
													} else {
														return (
															<div className='text-start mb-2'>
																<input
																	type='checkbox'
																	value={rack?._id}
																	onChange={(e) => handleSelectRack(e, rack)}
																/>
																<label>
																	<b>{rack?.name}</b>{' '}
																	<span style={{ fontSize: '12px' }}>
																		(F : {rack?.front}, B : {rack?.back}, H :{' '}
																		{rack?.height})
																	</span>
																</label>
															</div>
														);
													}
												})
											) : (
												<>No Stock Available</>
											)}
											{rId?.map((rackData) => (
												<div className='col-6 mt-2 mb-3'>
													<input
														type='number'
														style={{ width: '200px' }}
														placeholder={'Enter capacity for ' + rackData?.name}
														className='racks_input'
														onChange={(e) => fn_handleRacksData(e, rackData)}
													/>
												</div>
											))}
										</div>
										<div class='modal-footer'>
											<button
												type='button'
												class='btn btn-secondary'
												data-bs-dismiss='modal'
											>
												Close
											</button>
											<button
												type='submit'
												onClick={(e) => {
													e.preventDefault();
													addTransferIn(
														e.target,
														racksDetail.reduce((acc, i) => {
															return acc + parseInt(i?.qty);
														}, 0)
													);
												}}
												class='btn btn-success'
												data-bs-dismiss='modal'
												style={{ backgroundColor: '#ffd700', color: 'black' }}
											>
												Save changes
											</button>
										</div>
									</from>
								</div>
							</div>
						</div>
					</div>
					{/* Product Transfer Modal End */}
					<div className='bg-theme'>
						<ToastContainer />
						<div className='main-wrapper'>
							<div className='container py-4'>
								<div className='d-flex justify-content-between align-items-center'>
									<div>
										<h5 className='kk ps-3'>Manage Main Stock Racks</h5>
									</div>
								</div>
								<div className='d-flex justify-content-between'>
									<ul
										class='nav nav-pills mb-3 d-flex justify-content-start'
										id='pills-tab'
										role='tablist'
									>
										<li
											class='nav-item'
											role='presentation'
										>
											<button
												class='nav-link active'
												id='pills-Owned-tab'
												data-bs-toggle='pill'
												data-bs-target='#pills-Owned'
												type='button'
												role='tab'
												aria-controls='pills-Owned'
												aria-selected='true'
												onClick={()=>setTypeOfRack('owned')}
											>
												Owned
											</button>
										</li>
										<li
											class='nav-item'
											role='presentation'
										>
											<button
												class='nav-link '
												id='pills-rentSide-tab'
												data-bs-toggle='pill'
												data-bs-target='#pills-rentSide'
												type='button'
												role='tab'
												aria-controls='pills-rentSide'
												aria-selected='false'
												onClick={()=>setTypeOfRack('rental')}
											>
												My Rental
											</button>
										</li>
									</ul>
									<div>
										<button
											type='button'
											class='btn btn-success'
											data-bs-toggle='modal'
											data-bs-target='#exampleModal3'
											style={{ backgroundColor: '#ffd700', color: 'black' }}
										>
											Product Transfer
										</button>
										<button
											type='button'
											class='btn btn-success ms-2'
											data-bs-toggle='modal'
											data-bs-target='#createRacks'
											style={{ backgroundColor: '#ffd700', color: 'black' }}
										>
											Create Racks
										</button>
									</div>
								</div>

								<div
									class='tab-content'
									id='pills-tabContent'
								>
									<div
										class='tab-pane fade show active'
										id='pills-Owned'
										role='tabpanel'
										aria-labelledby='pills-Owned-tab'
									>
										<div className='d-flex justify-content-between'>
											<h4 className='py-4'>
												Total Number of Racks: {ownedRacks.length}
											</h4>
											<div
												className='d-flex'
												style={{ height: '3rem' }}
											>
												<Radio.Group
													defaultValue=''
													onChange={(e) => {
														setUnitSearch(e?.target?.value);
														setSearchTerm('');
													}}
													buttonStyle='solid'
													style={{
														display: 'flex',
														flexDirection: 'row',
														margin: '0 1rem',
													}}
												>
													<Radio.Button
														style={{ height: '3rem', lineHeight: '2.9rem' }}
														value=''
													>
														All
													</Radio.Button>
													<Radio.Button
														style={{ height: '3rem', lineHeight: '2.9rem' }}
														value='ft'
													>
														Feet
													</Radio.Button>
													<Radio.Button
														style={{ height: '3rem', lineHeight: '2.9rem' }}
														value='inch'
													>
														Inches
													</Radio.Button>
													<Radio.Button
														style={{ height: '3rem', lineHeight: '2.9rem' }}
														value='meter'
													>
														Meter
													</Radio.Button>
												</Radio.Group>
												<input
													type='text'
													className='form-control'
													value={searchTerm}
													onChange={(e) => setSearchTerm(e.target.value)}
													placeholder='Search Rack'
												/>
												<button
													type='button'
													class='btn btn-success ms-2'
													data-bs-toggle='modal'
													data-bs-target='#exampleModall'
													style={{
														width: '16rem',
														fontSize: '13px',
														backgroundColor: '#ffd700',
														color: 'black',
													}}
												>
													Transfer Racks
												</button>
											</div>
										</div>

										<div className='row g-4'>
											{filteredData.map((item) => {
												if (!item?.forSell) {
													if (item?.type === 'owned') {
														return (
															<div className='col-md-3'>
																<div
																	className='p-2'
																	style={{
																		backgroundColor: '#fff',
																		border: ' 1px solid rgb(243, 239, 239)',
																		borderRadius: '10px',
																	}}
																>
																	<div
																		className='order'
																		style={{
																			display: 'flex',
																			flexDirection: 'column',
																			justifyContent: 'space-between',
																		}}
																	>
																		<div
																			style={{ cursor: 'pointer' }}
																			data-bs-toggle='modal'
																			data-bs-target='#ownedModal1'
																			onClick={() => {
																				setRackId(item?._id);
																				setRackUpdate(item);
																			}}
																		>
																			<h4>{item?.name}</h4>
																		</div>
																		<div style={{ zIndex: '999' }}>
																			{item?.hasDimensions && (
																				<span
																					style={{
																						fontSize: '0.95rem',
																						backgroundColor: 'rgb(220 231 244)',
																						padding: '0.2rem 0.08rem',
																						borderRadius: '0.1rem',
																						textAlign: 'center',
																					}}
																				>
																					front:{' '}
																					<span style={{ fontWeight: '600' }}>
																						{item?.front}
																					</span>
																					, back:{' '}
																					<span style={{ fontWeight: '600' }}>
																						{item?.back}
																					</span>
																					, height:{' '}
																					<span style={{ fontWeight: '600' }}>
																						{item?.height}
																					</span>
																				</span>
																			)}
																		</div>
																	</div>

																	<div className='d-flex justify-content-between pt-3'>
																		<p>
																			{item?.state === 'empty' ? (
																				<span
																					style={{
																						textTransform: 'capitalize',
																					}}
																				>
																					{item?.state}
																				</span>
																			) : (
																				<>
																					Product : <b>{item?.productName}</b>
																					<br />
																					Qty :{' '}
																					<b>
																						{item?.productQty}{' '}
																						{item?.productUnit}
																					</b>
																				</>
																			)}
																		</p>
																		{item?.state === 'empty' ? (
																			<div
																				className=''
																				style={{
																					background: '#FEECDC',
																					width: '30px',
																					height: '30px',
																					lineHeight: '30px',
																					borderRadius: '50%',
																					justifyContent: 'center',
																					alignItems: 'center',
																					display: 'flex',
																				}}
																			>
																				<AiFillCloseCircle
																					size={20}
																					color='red'
																				/>
																			</div>
																		) : (
																			<div
																				className=''
																				style={{
																					background: '#FEECDC',
																					width: '30px',
																					height: '30px',
																					lineHeight: '30px',
																					borderRadius: '50%',
																					justifyContent: 'center',
																					alignItems: 'center',
																					display: 'flex',
																				}}
																			>
																				<i
																					class='fa-solid fa-check'
																					style={{
																						fontSize: '18px',

																						color: ' #ffd700',
																					}}
																				></i>
																			</div>
																		)}
																	</div>
																	<hr />
																	{item?.hasDimensions && (
																		<div className='mb-2'>
																			<span>
																				Type : <b>{item?.rackType}</b>
																			</span>
																		</div>
																	)}
																	<div>
																		<button
																			onClick={() =>
																				navigate(`/viewProduct/${item?._id}`)
																			}
																			class='btn w-100 text-right btn-success'
																		>
																			View Rack
																		</button>
																	</div>
																</div>
															</div>
														);
													}
												}
											})}
										</div>
										{/* owned model1 */}
										<div
											class='modal fade'
											id='ownedModal1'
											tabindex='-1'
											aria-labelledby='exampleModalLabel'
											aria-hidden='true'
										>
											<div class='modal-dialog w-100'>
												<div class='modal-content w-100'>
													<div class='modal-body w-100'>
														<form
															onSubmit={(e) => {
																e.preventDefault();
																updateSend(e.target);
															}}
														>
															<div className='row pt-4  align-items-center'>
																<div className='col-md-3'>
																	<label
																		for='inputPassword6'
																		className='form-label'
																	>
																		Select Unit
																	</label>
																</div>
																<div className='col-md-9'>
																	<Radio.Group
																		defaultValue={'ft'}
																		buttonStyle='solid'
																		name='unit'
																		onChange={(e) =>
																			setUnitSelect(e?.target?.value)
																		}
																	>
																		<Radio.Button value='ft'>Feet</Radio.Button>
																		<Radio.Button value='inch'>
																			Inch
																		</Radio.Button>
																		<Radio.Button value='meter'>
																			Meter
																		</Radio.Button>
																	</Radio.Group>
																</div>
															</div>
															<div className='row pt-4  align-items-center'>
																<div className='col-md-3'>
																	<label
																		for='inputPassword6'
																		className='form-label'
																	>
																		Front ({unitSelect})
																	</label>
																</div>
																<div className='col-md-9'>
																	<input
																		className='form-control'
																		name='front'
																		placeholder='Enter Rack Front Size'
																		defaultValue={rackUpdate?.front}
																	/>
																</div>
															</div>
															<div className='row  pt-4  align-items-center'>
																<div className='col-md-3'>
																	<label
																		for='inputPassword6'
																		className='form-label'
																	>
																		Back ({unitSelect})
																	</label>
																</div>
																<div className='col-md-9'>
																	<input
																		className='form-control'
																		name='back'
																		placeholder='Enter Rack Back Size'
																		defaultValue={rackUpdate?.back}
																	/>
																</div>
															</div>
															<div className='row  pt-4  align-items-center'>
																<div className='col-md-3'>
																	<label
																		for='inputPassword6'
																		className='form-label'
																	>
																		Height ({unitSelect})
																	</label>
																</div>
																<div className='col-md-9'>
																	<input
																		className='form-control'
																		name='height'
																		placeholder='Enter Rack Height'
																		defaultValue={rackUpdate?.height}
																	/>
																</div>
															</div>
															<div className='row  pt-4  align-items-center'>
																<div className='col-md-3'>
																	<label
																		for='inputPassword6'
																		className='form-label'
																	>
																		Rack Type
																	</label>
																</div>
																<div className='col-md-9'>
																	<Radio.Group
																		defaultValue={rackUpdate?.rackType.toString()}
																		buttonStyle='solid'
																		name='rackType'
																	>
																		<Radio.Button value='Floor Rack'>
																			Floor Rack
																		</Radio.Button>
																		<Radio.Button value='Hanger Rack'>
																			Hanger Rack
																		</Radio.Button>
																		<Radio.Button value='Shelf Rack'>
																			Shelf Rack
																		</Radio.Button>
																	</Radio.Group>
																</div>
															</div>
															<div className='row  pt-4  align-items-center'>
																<div className='col-md-3'>
																	<label
																		for='inputPassword6'
																		className='form-label'
																	>
																		Type
																	</label>
																</div>
																<div className='col-md-9'>
																	<input
																		className='form-control'
																		value={'Owned'}
																		disabled
																	/>
																</div>
															</div>
															{/* <div className="row  pt-4  align-items-center">
                                <div className="col-md-2">
                                  <label
                                    for="inputPassword6"
                                    className="form-label"
                                  >
                                    State
                                  </label>
                                </div>
                                <div className="col-md-10">
                                  <select
                                    class="form-control-input-2"
                                    name="state"
                                  >
                                    <option value="" disabled selected>
                                      ---Select---
                                    </option>
                                    <option value="occupied">Occupied</option>
                                    <option value="empty">Empty</option>
                                  </select>
                                </div>
                              </div> */}
															<div className='row'>
																<div className='col-lg-6 col-md-12 py-3'>
																	<button
																		type='button'
																		class='cancel-btn btn-lg'
																		data-bs-dismiss='modal'
																	>
																		Cancel
																	</button>
																</div>
																<div className='col-lg-6 col-md-12 py-3'>
																	<button
																		type='submit'
																		class='add-last-btn btn-lg'
																		data-bs-dismiss='modal'
																	>
																		Save
																	</button>
																</div>
															</div>
														</form>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div
										class='tab-pane fade '
										id='pills-rentSide'
										role='tabpanel'
										aria-labelledby='pills-rentSide-tab'
									>
										<div className='d-flex justify-content-between'>
											<h3 className='py-4'>
												Total Number of Racks: {myRentalRacks.length}
											</h3>
											<div
												className='d-flex'
												style={{ height: '3rem' }}
											>
												<Radio.Group
													defaultValue=''
													onChange={(e) => {
														setUnitSearch(e?.target?.value);
														setSearchTerm('');
													}}
													buttonStyle='solid'
													style={{
														display: 'flex',
														flexDirection: 'row',
														margin: '0 1rem',
													}}
												>
													<Radio.Button
														style={{ height: '3rem', lineHeight: '2.9rem' }}
														value=''
													>
														All
													</Radio.Button>
													<Radio.Button
														style={{ height: '3rem', lineHeight: '2.9rem' }}
														value='ft'
													>
														Feet
													</Radio.Button>
													<Radio.Button
														style={{ height: '3rem', lineHeight: '2.9rem' }}
														value='inch'
													>
														Inches
													</Radio.Button>
													<Radio.Button
														style={{ height: '3rem', lineHeight: '2.9rem' }}
														value='meter'
													>
														Meter
													</Radio.Button>
												</Radio.Group>
												<input
													type='text'
													className='form-control'
													value={searchTerm}
													onChange={(e) => setSearchTerm(e.target.value)}
													placeholder='Search Rack'
												/>
												<button
													type='button'
													class='btn btn-success ms-2'
													data-bs-toggle='modal'
													data-bs-target='#exampleModall'
													style={{
														width: '16rem',
														fontSize: '13px',
														backgroundColor: '#ffd700',
														color: 'black',
													}}
												>
													Transfer Racks
												</button>
											</div>
										</div>
										<div className='row g-4'>
											{filteredData.map((item, index) => {
												if (item?.forSell) {
													return (
														<div className='col-md-3'>
															<div
																className='p-2'
																style={{
																	backgroundColor: '#fff',
																	border: ' 1px solid rgb(243, 239, 239)',
																	borderRadius: '10px',
																}}
															>
																<div
																	className='order'
																	style={{
																		display: 'flex',
																		justifyContent: 'space-between',
																	}}
																>
																	<div
																		style={{ cursor: 'pointer' }}
																		data-bs-toggle='modal'
																		data-bs-target='#ownedModal1'
																		onClick={() => {
																			setRackId(item?._id);
																		}}
																	>
																		<h4>{item?.name}</h4>
																	</div>
																	<div style={{ zIndex: '999' }}>
																		{item?.hasDimensions && (
																			<h6>
																				(f:{item?.front}, b:{item?.back}, h:
																				{item?.height}) {item?.unit}
																			</h6>
																		)}
																	</div>
																</div>

																<div className='d-flex justify-content-between pt-3'>
																	<p>{item?.state}</p>
																	{item?.state === 'empty' ? (
																		<div
																			className=''
																			style={{
																				background: '#FEECDC',
																				width: '30px',
																				height: '30px',
																				lineHeight: '30px',
																				borderRadius: '50%',
																				justifyContent: 'center',
																				alignItems: 'center',
																				display: 'flex',
																			}}
																		>
																			<AiFillCloseCircle
																				size={20}
																				color='red'
																			/>
																		</div>
																	) : (
																		<div
																			className=''
																			style={{
																				background: '#FEECDC',
																				width: '30px',
																				height: '30px',
																				lineHeight: '30px',
																				borderRadius: '50%',
																				justifyContent: 'center',
																				alignItems: 'center',
																				display: 'flex',
																			}}
																		>
																			<i
																				class='fa-solid fa-check'
																				style={{
																					fontSize: '18px',

																					color: ' #ffd700',
																				}}
																			></i>
																		</div>
																	)}
																</div>
																<hr />
																<div>
																	<button
																		onClick={() =>
																			navigate(`/viewProduct/${item?._id}`)
																		}
																		class='btn  w-100 text-right btn-success'
																	>
																		View Rack
																	</button>
																</div>
															</div>
														</div>
													);
												}
											})}
										</div>

										<div
											class='modal fade'
											id='myRentalModal1'
											tabindex='-1'
											aria-labelledby='exampleModalLabel'
											aria-hidden='true'
										>
											<div class='modal-dialog w-100'>
												<div class='modal-content w-100'>
													<div class='modal-body w-100'>
														<form
															onSubmit={(e) => {
																e.preventDefault();
																updateSend(e.target);
															}}
														>
															<div className='row  pt-4  align-items-center'>
																<div className='col-md-2'>
																	<label
																		for='inputPassword6'
																		className='form-label'
																	>
																		width (ft)
																	</label>
																</div>
																<div className='col-md-10'>
																	<input
																		className='form-control'
																		name='width'
																		placeholder='Enter Size'
																	/>
																</div>
															</div>
															<div className='row  pt-4  align-items-center'>
																<div className='col-md-2'>
																	<label
																		for='inputPassword6'
																		className='form-label'
																	>
																		Length (ft)
																	</label>
																</div>
																<div className='col-md-10'>
																	<input
																		className='form-control'
																		name='height'
																		placeholder='Enter Size'
																	/>
																</div>
															</div>
															<div className='row  pt-4  align-items-center'>
																<div className='col-md-2'>
																	<label
																		for='inputPassword6'
																		className='form-label'
																	>
																		Type
																	</label>
																</div>
																<div className='col-md-10'>
																	<select
																		class='form-control-input-2 p-1'
																		aria-label='Default select example'
																		name='type'
																	>
																		<option
																			value=''
																			disabled
																			selected
																		>
																			---Select---
																		</option>
																		<option value='owned'>Owned</option>
																		<option value='myrental'>My Rental</option>
																		{/* <option value="onrental">On Rental</option> */}
																	</select>
																</div>
															</div>
															<div className='row  pt-4  align-items-center'>
																<div className='col-md-2'>
																	<label
																		for='inputPassword6'
																		className='form-label'
																	>
																		State
																	</label>
																</div>
																<div className='col-md-10'>
																	<select
																		class='form-control-input-2'
																		name='state'
																	>
																		<option
																			value=''
																			disabled
																			selected
																		>
																			---Select---
																		</option>
																		<option value='occupied'>Occupied</option>
																		<option value='empty'>Empty</option>
																	</select>
																</div>
															</div>
															<div className='row'>
																<div className='col-lg-6 col-md-12 py-3'>
																	<button
																		type='button'
																		class='cancel-btn btn-lg'
																		data-bs-dismiss='modal'
																	>
																		Cancel
																	</button>
																</div>
																<div className='col-lg-6 col-md-12 py-3'>
																	<button
																		type='submit'
																		class='add-last-btn btn-lg'
																		data-bs-dismiss='modal'
																	>
																		Save
																	</button>
																</div>
															</div>
														</form>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</header>
			</div>
		</>
	);
}

export default withAuth(MainStockRacks);
