import React, { useState, useEffect }from "react";
import ScrollableSection from "../Dashboard/Dashbord";
import Nav from "../../../components/Nav";

import { BiImport } from "react-icons/bi";
import { BiExport } from "react-icons/bi";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiOutlinePlus } from "react-icons/ai";
import { AiOutlineZoomIn } from "react-icons/ai";
import withAuth from "../../../hooks/withAuth";

import { getStore } from "../ApiEndpoints";





function Stores() {

 
  const [store, setStore] = useState([]);
  const [ambid, setAmbId] = useState('');

  const fetchStoreData = async (id) => {
    const result = await fetch(`${getStore}/${id}`);
    const catdata = await result.json();
    console.log('Store', catdata);
    setStore(catdata);

  };

  useEffect(() => {

    const id = localStorage.getItem('ambassadorID')
    setAmbId(id)
    console.log('================================', id, '================================')
 fetchStoreData(id);
  }, []);


  return (
    <>
      <div>
        <header>
          <div className="bg-theme">
            <div className="main-wrapper">
              <div className="container py-4">
                <h5 className="kk ps-3">Manage Store Requests</h5>

                <div className="min-box my-4">
                  <div className="products-table">
                    <table
                      className="table border rounded align-middle"
                      style={{ borderColor: "#4f4f51" }}
                    >
                      <thead className="bg-light">
                        <tr>
                          
                        
                          <th>#ID</th>
                          <th>Image</th>
                          <th>Brand Name</th>
                          <th>Name</th>
                          <th>Country</th>
                          <th>City</th>

                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {store?.map((item, index) => {
                          return (
                            <>
                              <tr>
                                
                                    
                                    <td>{index +1}</td>
                                    <td>
                                      {" "}
                                      <div className="prod-img">

                                        {/* image pending */}
                                        <img
                                          src={"p1.jpg"}
                                          alt="image pending"
                                        />
                                      </div>
                                    </td>
                                    <td>{item?.brandName}</td>
                                    <td>{item?.name}</td>

                                    <td>{item?.country}</td>
                                    <td>{item?.city}</td>

                                    <td>
                                      <div className="actions d-flex">
                                        <span style={{ fontSize: 21 }}>
                                          <FiEdit />
                                        </span>
                                        <span style={{ fontSize: 21 }} className="px-3">
                                          <RiDeleteBinLine />
                                        </span>
                                      </div>
                                    </td>
                                  
                               
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table >
                    <div className="data-pagination px-4 d-flex">
                      <p>SHOWING 41-60 OF 308</p>

                      <nav aria-label="Page navigation example">
                        <ul className="pagination">
                          <li className="page-item">
                            <a
                              className="page-link"
                              href="#"
                              aria-label="Previous"
                            >
                              <span aria-hidden="true">«</span>
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link" href="#">
                              1
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link" href="#">
                              2
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link" href="#">
                              3
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link" href="#">
                              4
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link" href="#">
                              5
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link" href="#">
                              6
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link" href="#" aria-label="Next">
                              <span aria-hidden="true">»</span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    </>
  );
}

export default withAuth(Stores);
