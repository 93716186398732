import React, { useState, useEffect } from 'react';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { BiImport } from 'react-icons/bi';
import { BiExport } from 'react-icons/bi';
import { AiOutlinePlus } from 'react-icons/ai';
import { AiOutlineZoomIn } from 'react-icons/ai';
import { useParams } from 'react-router-dom';
import { Modal } from 'antd';
import ApiUrl from '../BaseUrl';
import { useNavigate } from 'react-router-dom';
import withAuth from '../../../hooks/withAuth';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { getWareHouse,addWareHouse,delWareHouse,updateWarehouse } from '../ApiEndpoints'

import { useForm } from 'react-hook-form';


const schema = yup.object().shape({
	name: yup.string().required("Name is required"),
	width: yup.string().required("Width is required"),
	stockCapacity: yup.string().required("Stock capacity is required"),
	height: yup.string().required("Length is required"),
	address: yup.string().required("Address is required")

})

function EStock() {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm({
		resolver: yupResolver(schema),
	});



	const [categories, setCategories] = useState([]);
	const [warehouseID, setWarehouse] = useState("");
	const [singleStock, setSingleStock] = useState({});
	const [ambid, setAmbId] = useState('');

	

	const { id } = useParams();
	const navigate = useNavigate();

	const sendData = (values) => { };

	const updateData = async (data) => {


		try {
			
		const id = localStorage.getItem('ambassadorID')
		// const updatedData = {...data, id}
			const response = await fetch(`${updateWarehouse}/${id}/${warehouseID}`, {
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(data),
			});
			if (response.ok) {
				console.log(' warehouse update succesfull ');
				const eventData = await response.json();
				console.log('event added', eventData);
				reset()
				toast.success('warehouse update succesfull');
			} else {
				console.error(' failed');
				toast.error(' Addition failed');
			}
		} catch (error) {
			console.error('Error during product category:', error);
		}

	};
	const fetchWareHouseData = async (id) => {
		const result = await fetch(`${getWareHouse}/${id}`);
		const catdata = await result.json();
		console.log('Warehouse', catdata);
		setWareHouse(catdata);
	};

	const onDeleteStudent = (id) => {
		
		
		Modal.confirm({
			title: 'Are you sure you want to delete?',
			onOk: () => {
				fetch(`${delWareHouse}/${ambid}/${id}`, {
					method: 'DELETE',
					headers: {
						'Content-Type': 'application/json',
					},
				}).then((res) => {
					console.log('res', res);
					if (res.ok) {
						console.log('wareHouse deleted successful');
						fetchWareHouseData(ambid)
						toast.success('deleted successful');
					} else {
						console.log('seller deleted failed');
						toast.error(' deletetion failed');
					}
				});
			},
		});
	};

	const [mainStockProduct, setMainStockProduct] = useState(null);
	// console.log('=====mainStockProduct===', mainStockProduct);

	const [statequantity, setQuantity] = useState('');
	const [productId, setProdcutID] = useState('');
	const [statestoreId, setStoreId] = useState('');
	// onsole.log('===++===++==', statequantity);
	// console.log('-=-------', productId);
	// console.log('============++===++', sctatestoreId);
	const addTransferIn = () => { };

	const [warehouse, setWareHouse] = useState([]);

	useEffect(() => {

		const id = localStorage.getItem('ambassadorID')
		setAmbId(id)
		// console.log('================================', id, '================================')


		fetchWareHouseData(id);
	}, []);
	const onSubmit = async (data) => {


		try {
			
		const id = localStorage.getItem('ambassadorID')
		// const updatedData = {...data, id}
			const response = await fetch(`${addWareHouse}/${id}`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(data),
			});
			if (response.ok) {
				console.log(' warehouse added succesfull ');
				const eventData = await response.json();
				console.log('event added', eventData);
				reset()
				toast.success('warehouse added succesfull');
			} else {
				console.error(' failed');
				toast.error(' Addition failed');
			}
		} catch (error) {
			console.error('Error during product category:', error);
		}

		// console.log('esre house data', data);
		// reset();
	};

	return (
		<>
			<div>
				<header>
					<div className='bg-theme'>
						<ToastContainer />
						<div className='main-wrapper'>
							<div className='container py-4'>
								<div className='d-flex justify-content-between'>
									<h5 className='kk ps-3'> Manage E-Stock</h5>
								</div>

								{/* <!-- Modal --> */}
								<div
									class='modal fade'
									id='exampleModal3'
									tabindex='-1'
									aria-labelledby='exampleModalLabel'
									aria-hidden='true'
								>
									<div class='modal-dialog'>
										<div class='modal-content'>
											<div class='modal-header'>
												<h5
													class='modal-title'
													id='exampleModalLabel'
												>
													Modal title
												</h5>
												<button
													type='button'
													class='btn-close'
													data-bs-dismiss='modal'
													aria-label='Close'
												></button>
											</div>
											<div class='modal-body'>
												<from>
													<div className='col-md-12 text-start'>
														<label>Product</label>
														<select
															onChange={(e) => setProdcutID(e.target.value)}
															name='productID'
															class='form-control-input-2'
														>
															<option selected>
																--select product seller---
															</option>
															{/* {mainStockProduct?.map((item, index) => {
                                      return (

                                        <option value={item?._id}>
                                          {item?.title}
                                        </option>
                                      );
                                    })} */}
														</select>
													</div>
													<div className='col-md-12 text-start'>
														<label>Store</label>
														<select
															name='storeID'
															onChange={(e) => setStoreId(e.target.value)}
															class='form-control-input-2'
														>
															<option>--select store name---</option>
															{categories?.map((item, index) => {
																return (
																	<option value={item?._id}>
																		{item?.brandName}
																	</option>
																);
															})}
														</select>
													</div>

													<div className='col-md-12 pt-3 text-start'>
														<label>Quantity</label>
														<input
															name='quantity'
															type='text'
															onChange={(e) => setQuantity(e.target.value)}
															className='form-control'
															placeholder='Enter quantity'
														/>
													</div>
													<div class='modal-footer'>
														<button
															type='button'
															class='btn btn-secondary'
															data-bs-dismiss='modal'
														>
															Close
														</button>
														<button
															type='submit'
															onClick={(e) => {
																e.preventDefault();
																addTransferIn(e.target);
															}}
															class='btn btn-primary'
															data-bs-dismiss='modal'
														>
															Save changes
														</button>
													</div>
												</from>
											</div>
										</div>
									</div>
								</div>

								<div className='min-box  border'>
									<div className='products-actions d-flex p-4'>
										<div className='action-btn'>
											<div className='actions d-flex'>
												<div className='Bulk-btn'>
													<div
														className='offcanvas offcanvas-end'
														tabIndex={-1}
														id='offcanvasRight2'
														aria-labelledby='offcanvasRightLabel'
													>
														<div className='offcanvas-header px-4'>
															<div className='content-header'>
																<h4>Manage E-Stock </h4>
																<p>
																	Update E-Stock info, combinations and extras.
																</p>
															</div>

															<button
																type='button'
																className='btn-close bg-white shadow rounded-5 text-reset'
																data-bs-dismiss='offcanvas'
																aria-label='Close'
																style={{
																	width: '5px',
																	height: '30px',
																	opacity: '0.8',
																	fontSize: '12px',
																}}
															/>
														</div>
														{/* Canvas */}

														<div className='offcanvas-body p-0'>
															<div className='form-data'>
																<div className='wrap-data p-5'>
																	<form
																		
																			onSubmit={handleSubmit(onSubmit)}
																			// handleSubmit(onSubmit);

																		
																	>
																		<div className='row  pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Warehouse Name
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='text'
																					name='warehouseName'
																					className='form-control-input'
																					placeholder='WareHouse Name'
																					{...register('name')}
																				/>
																				{errors.name && (
																					<p className='text-danger'>
																						{errors.name.message}
																					</p>
																				)}
																			</div>
																		</div>

																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Warehouse Width (ft)
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='text'
																					name='width'
																					className='form-control-input'
																					placeholder='Warehouse width'
																					{...register('width')}
																				/>
																				{errors.width && (
																					<p className='text-danger'>
																						{errors.width.message}
																					</p>
																				)}
																			</div>
																		</div>
																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Warehouse Length (ft)
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='text'
																					name='height'
																					className='form-control-input'
																					placeholder='Warehouse Height'
																					{...register('height')}
																				/>
																				{errors.height && (
																					<p className='text-danger'>
																						{errors.height.message}
																					</p>
																				)}
																			</div>
																		</div>
																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Stock Capacity
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='text'
																					name='stockCapacity'
																					className='form-control-input'
																					placeholder='Stock Capacity'
																					{...register('stockCapacity')}
																				/>
																				{errors.stockCapacity && (
																					<p className='text-danger'>
																						{errors.stockCapacity.message}
																					</p>
																				)}
																			</div>
																		</div>
																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Address
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='text'
																					name='address'
																					className='form-control-input'
																					placeholder='Address'
																					{...register('address')}
																				/>
																				{errors.address && (
																					<p className='text-danger'>
																						{errors.address.message}
																					</p>
																				)}
																			</div>
																		</div>

																		<div className='offcanvas-footer px-5 py-3'>
																			<div className='content-footer'>
																				<div className='row'>
																					<div className='col-lg-6 col-md-12 py-3'>
																						<button
																							type='button'
																							class='cancel-btn btn-lg'
																						>
																							Cancel
																						</button>
																					</div>
																					<div className='col-lg-6 col-md-12 py-3'>
																						<button
																							type='submit'
																							class='add-last-btn btn-lg'
																						>
																							Save
																						</button>
																					</div>
																				</div>
																			</div>
																		</div>
																	</form>
																</div>
															</div>
														</div>
														{/* Canvas */}
													</div>
													<div
														className='offcanvas offcanvas-end'
														tabIndex={-1}
														id='offcanvasRightupdate12'
														aria-labelledby='offcanvasRightLabel'
													>
														<div className='offcanvas-header px-4'>
															<div className='content-header'>
																<h4>Manage E-Stock </h4>
																<p>
																	Update E-Stock info, combinations and extras.
																</p>
															</div>

															<button
																type='button'
																className='btn-close bg-white shadow rounded-5 text-reset'
																data-bs-dismiss='offcanvas'
																aria-label='Close'
																style={{
																	width: '5px',
																	height: '30px',
																	opacity: '0.8',
																	fontSize: '12px',
																}}
															/>
														</div>
														{/* Canvas */}

														<div className='offcanvas-body p-0'>
															<div className='form-data'>
																<div className='wrap-data p-5'>
																	<form
																		onSubmit={handleSubmit(updateData)}
																	>
																		<div className='row  pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Warehouse Name
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='text'
																					name='warehouseName'
																					className='form-control-input'
																					placeholder='WareHouse Name'
																					defaultValue={singleStock.name}
																					{...register('name')}
																				/>
																			</div>
																		</div>

																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					WareHouse Weight
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='text'
																					name='width'
																					className='form-control-input'
																					placeholder='WareHouse Weight'
																					defaultValue={singleStock?.width}
																					{...register('width')}	
																				/>
																			</div>
																		</div>
																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					WareHouse Length(ft)
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='text'
																					name='height'
																					className='form-control-input'
																					placeholder='WareHouse Height'
																					defaultValue={singleStock?.height}
																					{...register('height')}	
																				/>
																			</div>
																		</div>
																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Stock Capacity
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='text'
																					name='stockCapacity'
																					className='form-control-input'
																					placeholder='Stock Capacity'
																					defaultValue={
																						singleStock?.stockCapacity
																					}
																					{...register('stockCapacity')}    
                                                                                
																				/>
																			</div>
																		</div>
																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Address
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='text'
																					name='address'
																					className='form-control-input'
																					placeholder='Address'
																					defaultValue={singleStock?.address}
																					{...register('address')}
                                                                                />
																				
																			</div>
																		</div>

																		<div className='offcanvas-footer px-5 py-3'>
																			<div className='content-footer'>
																				<div className='row'>
																					<div className='col-lg-6 col-md-12 py-3'>
																						<button
																							type='button'
																							class='cancel-btn btn-lg'
																						>
																							Cancel
																						</button>
																					</div>
																					<div className='col-lg-6 col-md-12 py-3'>
																						<button
																							type='submit'
																							class='add-last-btn btn-lg'
																						>
																							Save
																						</button>
																					</div>
																				</div>
																			</div>
																		</div>
																	</form>
																</div>
															</div>
														</div>
														{/* Canvas */}
													</div>
												</div>

												{/* Modal-Del */}
												<button
													className='add-btn'
													type='button'
													data-bs-toggle='offcanvas'
													data-bs-target='#offcanvasRight2'
													aria-controls='offcanvasRight2'
												>
													<AiOutlinePlus />
													<span className='px-1'> Add Stock</span>
												</button>

												<div
													className='offcanvas offcanvas-end w-75'
													tabIndex={-1}
													id='offcanvasRight'
													aria-labelledby='offcanvasRightLabel'
												>
													<div className='offcanvas-header px-4'>
														<div className='content-header'>
															<h4> Add Stock</h4>
															<p>Add Stock info, combinations and extras.</p>
														</div>

														<button
															type='button'
															className='btn-close text-reset'
															data-bs-dismiss='offcanvas'
															aria-label='Close'
														/>
													</div>
													{/* Canvas */}

													{/* Canvas */}
												</div>
												<button
													type='button'
													class='btn btn-primary ms-3'
													data-bs-toggle='modal'
													data-bs-target='#exampleModal3'
												>
													Product Transfer
												</button>
											</div>
										</div>
									</div>
								</div>

								<div className='min-box my-4'>
									<div className='products-table'>
										<table
											className='table border rounded align-middle'
											style={{ borderColor: '#4f4f51' }}
										>
											<thead className='bg-light'>
												<tr>
													<th>#Id</th>
													<th>WareHouse Name</th>
													<th>WareHouse size</th>
													{/* <th>Stock Capacity</th> */}
													<th>Location</th>
													<th>Status</th>
													<th>ACTIONS</th>
												</tr>
											</thead>
											<tbody>
												{warehouse?.map((item, index) => {
													return (
														<>
															<tr>
																<td>{item?.id}</td>
																<td>{item?.name}</td>
																<td>{`${item?.height}  x ${item?.width}`}</td>
																<td>{item?.address}</td>
																<td>
																	<span
																		className='text-ligh  '
																		style={{
																			color: '#278a1a',
																			fontSize: '10px',
																			backgroundColor: '#41ff2848',
																			borderRadius: 20,
																			padding: '5px 10px',
																		}}
																	>
																		{item?.status}
																	</span>
																</td>

																<td>
																	<div className='actions d-flex'>
																		<span
																			onClick={() => {
																				setWarehouse(item?._id);
																				setSingleStock(item)
																			}}
																			data-bs-toggle='offcanvas'
																			data-bs-target='#offcanvasRightupdate12'
																			aria-controls='offcanvasRightupdate12'
																			style={{ fontSize: 21 }}
																		>
																			<FiEdit />
																		</span>

																		<span
																			onClick={() => {
																				onDeleteStudent(item?._id);
																			}}
																			style={{ fontSize: 21 }}
																			className='px-3'
																		>
																			<RiDeleteBinLine />
																		</span>
																	</div>
																</td>
															</tr>
														</>
													);
												})}
											</tbody>
										</table>
										<div className='data-pagination px-4 d-flex'>
											<p>SHOWING 41-60 OF 308</p>

											<nav aria-label='Page navigation example'>
												<ul className='pagination'>
													<li className='page-item'>
														<a
															className='page-link'
															href='#'
															aria-label='Previous'
														>
															<span aria-hidden='true'>«</span>
														</a>
													</li>
													<li className='page-item'>
														<a
															className='page-link'
															href='#'
														>
															1
														</a>
													</li>
													<li className='page-item'>
														<a
															className='page-link'
															href='#'
														>
															2
														</a>
													</li>
													<li className='page-item'>
														<a
															className='page-link'
															href='#'
														>
															3
														</a>
													</li>
													<li className='page-item'>
														<a
															className='page-link'
															href='#'
														>
															4
														</a>
													</li>
													<li className='page-item'>
														<a
															className='page-link'
															href='#'
														>
															5
														</a>
													</li>
													<li className='page-item'>
														<a
															className='page-link'
															href='#'
														>
															6
														</a>
													</li>
													<li className='page-item'>
														<a
															className='page-link'
															href='#'
															aria-label='Next'
														>
															<span aria-hidden='true'>»</span>
														</a>
													</li>
												</ul>
											</nav>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</header>
			</div>
		</>
	);
}

export default withAuth(EStock);
