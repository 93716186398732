import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ApiUrl from '../BaseUrl';
import { toast } from 'react-toastify';
import withAuth from '../../../hooks/withAuth';

function RequestAmb() {
	const [reqAmb, setReqAmb] = useState([]);
	const [ambArray, setAmbArray] = useState([]);

	const fn_accept = (i) => {};
	console.log(ambArray);
	return (
		<div className='container'>
			<h3 className='m-2'>List of Sub Ambassador Requests</h3>

			<div className='row'>
				{ambArray?.map((i) => {
					return (
						<div className='col-md-12 mt-3 '>
							<div
								className='amd_data d-flex'
								style={{
									justifyContent: 'space-between',
									justifyContent: 'space-around',
									alignItems: 'center',
									flexWrap: 'wrap',
									borderRadius: '20px',
								}}
							>
								<div>
									<img
										src={`${ApiUrl}/images/${i?.profileImg}`}
										height={'150px'}
										alt='Amb_img'
										style={{ borderRadius: '20px' }}
									/>
								</div>
								<div style={{ fontSize: '0.9rem' }}>
									<span>
										<b>ID :</b> {i._id}
									</span>
									<br />
									<span>
										<b>Name :</b> {i.username}
									</span>
									<br />
									<span>
										<b>Contact No:</b> {i.countryCode} {i.phoneNo}
									</span>
									<br />
									<span>
										<b>Office Address:</b> {i.address}
									</span>
									<br />
									<span>
										<b>City :</b> {i.city}
									</span>
									<br />
									<span>
										<b>Country:</b> {i.country}
									</span>
									<br />
								</div>
								<div>
									<button
										type='submit'
										className='btn btn-success mt-2'
										width='100px'
										height='100px'
										onClick={() => fn_accept(i)}
									>
										Accept
									</button>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default withAuth(RequestAmb);
