import React, { useState, useEffect, useRef } from 'react';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { AiOutlinePlus, AiFillDelete, AiFillCloseCircle } from 'react-icons/ai';
import { Modal } from 'antd';
import { AiOutlineZoomIn } from 'react-icons/ai';
import ApiUrl from '../BaseUrl';
import { useNavigate, useParams } from 'react-router-dom';
import withAuth from '../../../hooks/withAuth';

function ERacks() {
	const navigate = useNavigate();
	const { id } = useParams();
	const [racks, setRacks] = useState([]);
	const [fracks, setFRacks] = useState([]);
	const [rackId, setRackId] = useState(null);
	const [searchTerm, setSearchTerm] = useState('');

	useEffect(() => {
		setFRacks(racks?.map((obj, i) => ({ ...obj, name: 'Ei' + (i + 1) })));
		console.log('==========frack', fracks);
	}, [racks]);

	// const ownedRacks = racks?.filter((own) => own?.type === "owned" && own?.forSell === false)
	const ownedRacks = racks?.filter((own) => own?.type === 'owned');
	console.log('===============owned racks===============', ownedRacks);
	// const onRentalRacks = racks?.filter((own) => own?.type === "onrental" && own?.forSell === false)
	const onRentalRacks = racks?.filter((own) => own?.type === 'onrental');
	console.log(
		'===============onRentalRacks racks================',
		onRentalRacks
	);
	// const myRentalRacks = racks?.filter((own) => own?.type === "myrental" && own?.forSell === false)
	const myRentalRacks = racks?.filter((own) => own?.type === 'myrental');
	console.log(
		'===============myRentalRacks racks================',
		myRentalRacks
	);

	const filteredData = fracks?.filter((item) => {
		return item?.name?.includes(searchTerm);
	});

	console.log(filteredData, 'foltered===========');

	const updateSend = (values) => { };

	return (
		<>
			<div>
				<header>
					<div className='bg-theme'>
						<ToastContainer />
						<div className='main-wrapper'>
							<div className='container py-4'>
								<div className='d-flex justify-content-between'>
									<h5 className='kk ps-3'> Manage E-Racks</h5>
								</div>

								{/* Modal-Del */}

								<div
									className='offcanvas offcanvas-end'
									tabIndex={-1}
									id='offcanvasRight'
									aria-labelledby='offcanvasRightLabel'
								>
									<div className='offcanvas-header px-4'>
										<div className='content-header'>
											<h4>Manage E-Racks </h4>
											<p>Update E-Stock info, combinations and extras.</p>
										</div>

										<button
											type='button'
											className='btn-close bg-white shadow rounded-5 text-reset'
											data-bs-dismiss='offcanvas'
											aria-label='Close'
											style={{
												width: '5px',
												height: '30px',
												opacity: '0.8',
												fontSize: '12px',
											}}
										/>
									</div>
									{/* Canvas */}

									<div className='offcanvas-body p-0'>
										<div className='form-data'>
											<div className='wrap-data p-5'>
												<form
												// onSubmit={(e) => {
												//   e.preventDefault();
												//   sendData(e.target);
												// }}
												>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Store Name
															</label>
														</div>
														<div className='col-md-8'>
															<select
																name='experience'
																id='experience'
																class='form-control-input-2'
															>
																<option value='1'>KFC</option>
																<option value='1'></option>
															</select>
														</div>
													</div>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Select Number of Racks
															</label>
														</div>
														<div className='col-md-8'>
															<select
																name='rackcouts'
																id='rackcouts'
																class='form-control-input-2'
															>
																<option value='1'>1</option>
																<option value='2'>2</option>
																<option value='3'>3</option>
																<option value='4'>4</option>
																<option value='5'>5</option>
																<option value='6'>6</option>
																<option value='7'>7</option>
																<option value='8'>8</option>
																<option value='9'>9</option>
																<option value='10'>10</option>
																<option value='11'>11</option>
																<option value='12'>12</option>
															</select>
														</div>
													</div>

													<div></div>

													<div className='offcanvas-footer px-5 py-3'>
														<div className='content-footer'>
															<div className='row'>
																<div className='col-lg-6 col-md-12 py-3'>
																	<button
																		type='button'
																		class='cancel-btn btn-lg'
																	>
																		Cancel
																	</button>
																</div>
																<div className='col-lg-6 col-md-12 py-3'>
																	<button
																		type='submit'
																		class='add-last-btn btn-lg'
																	>
																		Save
																	</button>
																</div>
															</div>
														</div>
													</div>
												</form>
											</div>
										</div>
									</div>
									{/* Canvas */}
								</div>

								<div
									className='offcanvas offcanvas-end'
									tabIndex={-1}
									id='offcanvasRight'
									aria-labelledby='offcanvasRightLabel'
								>
									<div className='offcanvas-header px-4'>
										<div className='content-header'>
											<h4> Manage Seller Create / Update</h4>
											<p>Update products info, combinations and extras.</p>
										</div>

										<button
											type='button'
											className='btn-close bg-white shadow rounded-5 text-reset'
											data-bs-dismiss='offcanvas'
											aria-label='Close'
											style={{
												width: '5px',
												height: '30px',
												opacity: '0.8',
												fontSize: '12px',
											}}
										/>
									</div>
									{/* Canvas */}

									<div className='offcanvas-body p-0'>
										<div className='form-data'>
											<div className='wrap-data p-5'>
												<form
												// onSubmit={(e) => {
												//   e.preventDefault();
												//   sendData(e.target);
												// }}
												>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Experience Status
															</label>
														</div>
														<div className='col-md-8'>
															<select
																name='experience'
																id='experience'
																class='form-control-input-2'
															>
																<option value='1'>Under Training</option>
																<option value='1'>Verified</option>
															</select>
														</div>
													</div>

													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Profile Image
															</label>
														</div>
														<div className='col-md-8'>
															<div className='col-span-8 sm:col-span-4'>
																<div className='w-full text-center'>
																	<div
																		className='border-2 border-gray-300 dark:border-gray-600 border-dashed rounded-3 cursor-pointer px-6 pt-5 pb-4'
																		role='button'
																		tabIndex={0}
																		style={{
																			borderStyle: 'dotted',
																			borderColor: '#ffd700',
																		}}
																	>
																		<input
																			accept='image/*'
																			multiple=''
																			type='file'
																		/>
																		<span className='mx-auto flex justify-center'>
																			<svg
																				stroke='currentColor'
																				fill='none'
																				strokeWidth={2}
																				viewBox='0 0 24 24'
																				strokeLinecap='round'
																				strokeLinejoin='round'
																				className='text-3xl text-green-500'
																				height='1em'
																				width='1em'
																				xmlns='http://www.w3.org/2000/svg'
																			>
																				<polyline points='16 16 12 12 8 16' />
																				<line
																					x1={12}
																					y1={12}
																					x2={12}
																					y2={21}
																				/>
																				<path d='M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3' />
																				<polyline points='16 16 12 12 8 16' />
																			</svg>
																		</span>
																		<p className='text-sm mt-2'>
																			Drag your images here
																		</p>
																		<em className='text-xs text-gray-400'>
																			(Only *.jpeg, *.webp and *.png images will
																			be accepted)
																		</em>
																	</div>
																	<div className='text-green-500' />
																	<aside className='flex flex-row flex-wrap mt-4' />
																</div>
															</div>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Youtube Video Id
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																id='inputPassword6'
																className='form-control-input'
																placeholder='Youtube Video Link'
															/>
														</div>
													</div>

													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Status
															</label>
														</div>
														<div className='col-md-8'>
															<select class='form-control-input-2'>
																<option value='1'>Active</option>
																<option value='1'>Inactive</option>
															</select>
														</div>
													</div>

													<div className='offcanvas-footer px-5 py-3'>
														<div className='content-footer'>
															<div className='row'>
																<div className='col-lg-6 col-md-12 py-3'>
																	<button
																		type='button'
																		class='cancel-btn btn-lg'
																	>
																		Cancel
																	</button>
																</div>
																<div className='col-lg-6 col-md-12 py-3'>
																	<button
																		type='submit'
																		class='add-last-btn btn-lg'
																	>
																		Save
																	</button>
																</div>
															</div>
														</div>
													</div>
												</form>
											</div>
										</div>
									</div>

									<div
										className='offcanvas offcanvas-end'
										tabIndex={-1}
										id='offcanvasRight'
										aria-labelledby='offcanvasRightLabel'
									>
										<div className='offcanvas-header px-4'>
											<div className='content-header'>
												<h4>Manage Posts Create / Update</h4>
												<p>Update products info, combinations and extras.</p>
											</div>

											<button
												type='button'
												className='btn-close bg-white shadow rounded-5 text-reset'
												data-bs-dismiss='offcanvas'
												aria-label='Close'
												style={{
													width: '5px',
													height: '30px',
													opacity: '0.8',
													fontSize: '12px',
												}}
											/>
										</div>
										{/* Canvas */}
										<div className='offcanvas-body p-0'>
											<div className='form-data'>
												<div className='wrap-data p-5'>
													<form>
														<div className='row g-4 pt-4  align-items-center'>
															<div className='col-md-4'>
																<label
																	for='inputPassword6'
																	className='form-label'
																>
																	Post Title
																</label>
															</div>
															<div className='col-md-8'>
																<input
																	type='password'
																	id='inputPassword6'
																	className='form-control-input'
																	aria-labelledby='passwordHelpInline'
																	placeholder='Title'
																/>
															</div>
														</div>

														<div className='row  pt-4  align-items-center'>
															<div className='col-md-4'>
																<label
																	for='inputPassword6'
																	className='form-label'
																>
																	Status
																</label>
															</div>
															<div className='col-md-8'>
																<select
																	class='form-control-input-2'
																	aria-label='Default select example'
																>
																	<option value='1'>Active</option>
																	<option value='1'>Inactive</option>
																</select>
															</div>
														</div>
													</form>
												</div>
											</div>
										</div>
										<div className='offcanvas-footer px-5 py-3'>
											<div className='content-footer'>
												<div className='row'>
													<div className='col-lg-6 col-md-12 py-3'>
														<button
															type='button'
															class='cancel-btn btn-lg'
														>
															Cancel
														</button>
													</div>
													<div className='col-lg-6 col-md-12 py-3'>
														<button
															type='button'
															class='btn-close text-reset'
															data-bs-dismiss='offcanvas'
															aria-label='Close'
														>
															Save
														</button>
													</div>
												</div>
											</div>
										</div>
										{/* Canvas */}
									</div>

									{/* Canvas */}
								</div>
								<div className='all_categories'>
									<ul
										class='nav nav-pills mb-3'
										id='pills-tab'
										role='tablist'
									>
										<li
											class='nav-item'
											role='presentation'
										>
											<button
												class='nav-link active'
												id='pills-Owned-tab'
												data-bs-toggle='pill'
												data-bs-target='#pills-Owned'
												type='button'
												role='tab'
												aria-controls='pills-Owned'
												aria-selected='true'
											>
												Owned
											</button>
										</li>
										<li
											class='nav-item'
											role='presentation'
										>
											<button
												class='nav-link '
												id='pills-rentSide-tab'
												data-bs-toggle='pill'
												data-bs-target='#pills-rentSide'
												type='button'
												role='tab'
												aria-controls='pills-rentSide'
												aria-selected='false'
											>
												My Rental
											</button>
										</li>
										{/* <li class="nav-item" role="presentation">
                      <button class="nav-link " id="pills-onSide-tab" data-bs-toggle="pill" data-bs-target="#pills-onSide" type="button" role="tab" aria-controls="pills-onSide" aria-selected="false">On Rent</button>
                    </li> */}
									</ul>

									<div
										class='tab-content'
										id='pills-tabContent'
									>
										{/* owned data */}
										<div
											class='tab-pane fade show active'
											id='pills-Owned'
											role='tabpanel'
											aria-labelledby='pills-Owned-tab'
										>
											<div className='d-flex justify-content-between'>
												<h3 className='py-4'>
													Total Number of Racks: {ownedRacks.length}
												</h3>
												<div
													className='d-flex'
													style={{ height: '3rem' }}
												>
													<input
														type='text'
														className='form-control'
														value={searchTerm}
														onChange={(e) => setSearchTerm(e.target.value)}
														placeholder='Search Rack'
													/>
													{/* <button type="button" class="btn btn-primary ms-2" data-bs-toggle="modal" data-bs-target="#exampleModall" style={{width:"15rem"}}>
                          Transfer Racks
                        </button> */}
												</div>
											</div>
											<div className='row g-4'>
												{filteredData.map((item, index) => {
													if (item?.type === 'owned') {
														if (!item?.forSell) {
															return (
																<div className='col-md-3'>
																	{/* owned Racks */}
																	<div
																		className='p-2'
																		style={{
																			backgroundColor: '#fff',
																			border: ' 1px solid rgb(243, 239, 239)',
																			borderRadius: '10px',
																		}}
																	>
																		<div
																			className='order'
																			style={{
																				display: 'flex',
																				justifyContent: 'space-between',
																			}}
																		>
																			<div
																				className=''
																				data-bs-toggle='modal'
																				data-bs-target='#exampleModal12'
																				onClick={() => setRackId(item?._id)}
																			>
																				<h4>{item?.name}</h4>
																			</div>
																			<div style={{ zIndex: '999' }}></div>
																		</div>

																		<div className='d-flex justify-content-between pt-3'>
																			<p>{item?.state}</p>
																			{item?.state === 'empty' ? (
																				<div
																					className=''
																					style={{
																						background: '#FEECDC',
																						width: '30px',
																						height: '30px',
																						lineHeight: '30px',
																						borderRadius: '50%',
																						justifyContent: 'center',
																						alignItems: 'center',
																						display: 'flex',
																					}}
																				>
																					<AiFillCloseCircle
																						size={20}
																						color='red'
																					/>
																				</div>
																			) : (
																				<div
																					className=''
																					style={{
																						background: '#FEECDC',
																						width: '30px',
																						height: '30px',
																						lineHeight: '30px',
																						borderRadius: '50%',
																						justifyContent: 'center',
																						alignItems: 'center',
																						display: 'flex',
																					}}
																				>
																					<i
																						class='fa-solid fa-check'
																						style={{
																							fontSize: '18px',
																							color: ' #ffd700',
																						}}
																					></i>
																				</div>
																			)}
																		</div>

																		<div>
																			<button
																				onClick={() =>
																					navigate(
																						`/viewRackProduct/${item?._id}`
																					)
																				}
																				class='btn  w-100 text-right btn-success'
																			>
																				View Product
																			</button>
																		</div>
																	</div>

																	{/* owned Racks Modal */}

																	<div
																		class='modal fade'
																		id='exampleModal12'
																		tabindex='-1'
																		aria-labelledby='exampleModalLabel'
																		aria-hidden='true'
																	>
																		<div class='modal-dialog w-100'>
																			<div class='modal-content w-100'>
																				<div class='modal-body w-100'>
																					<form
																						onSubmit={(e) => {
																							e.preventDefault();
																							updateSend(e.target);
																						}}
																					>
																						<div className='row  pt-4  align-items-center'>
																							<div className='col-md-2'>
																								<label
																									for='inputPassword6'
																									className='form-label'
																								>
																									Width (ft)
																								</label>
																							</div>
																							<div className='col-md-10'>
																								<input
																									className='form-control'
																									name='width'
																									placeholder='Enter Size'
																								/>
																							</div>
																						</div>
																						<div className='row  pt-4  align-items-center'>
																							<div className='col-md-2'>
																								<label
																									for='inputPassword6'
																									className='form-label'
																								>
																									Length (ft)
																								</label>
																							</div>
																							<div className='col-md-10'>
																								<input
																									className='form-control'
																									name='height'
																									placeholder='Enter Size'
																								/>
																							</div>
																						</div>
																						<div className='row  pt-4  align-items-center'>
																							<div className='col-md-2'>
																								<label
																									for='inputPassword6'
																									className='form-label'
																								>
																									Type
																								</label>
																							</div>
																							<div className='col-md-10'>
																								<select
																									class='form-control-input-2 p-1'
																									aria-label='Default select example'
																									name='type'
																								>
																									<option
																										value=''
																										disabled
																										selected
																									>
																										---select---
																									</option>
																									<option value='owned'>
																										Owned
																									</option>
																									<option value='myrental'>
																										My Rental
																									</option>
																									{/* <option value="onrental">On Rent</option> */}
																								</select>
																							</div>
																						</div>
																						<div className='row  pt-4  align-items-center'>
																							<div className='col-md-2'>
																								<label
																									for='inputPassword6'
																									className='form-label'
																								>
																									State
																								</label>
																							</div>
																							<div className='col-md-10'>
																								<select
																									class='form-control-input-2'
																									name='state'
																								>
																									<option
																										value=''
																										selected
																										disabled
																									>
																										---Select---
																									</option>
																									<option value='occupied'>
																										Occupied
																									</option>
																									<option value='empty'>
																										Empty
																									</option>
																								</select>
																							</div>
																						</div>
																						<div className='row'>
																							<div className='col-lg-6 col-md-12 py-3'>
																								<button
																									type='button'
																									class='cancel-btn btn-lg'
																									data-bs-dismiss='modal'
																								>
																									Cancel
																								</button>
																							</div>
																							<div className='col-lg-6 col-md-12 py-3'>
																								<button
																									type='submit'
																									class='add-last-btn btn-lg'
																									data-bs-dismiss='modal'
																								>
																									Save
																								</button>
																							</div>
																						</div>
																					</form>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															);
														}
													}
												})}
											</div>
										</div>

										{/* myrental data */}

										<div
											class='tab-pane fade '
											id='pills-rentSide'
											role='tabpanel'
											aria-labelledby='pills-rentSide-tab'
										>
											{/* <h3 className="py-4">
                        Total Number of Racks: {myRentalRacks?.length}
                      </h3> */}
											<div className='d-flex justify-content-between'>
												<h3 className='py-4'>
													Total Number of Racks: {myRentalRacks?.length}
												</h3>
												<div
													className='d-flex'
													style={{ height: '3rem' }}
												>
													<input
														type='text'
														className='form-control'
														value={searchTerm}
														onChange={(e) => setSearchTerm(e.target.value)}
														placeholder='Search Rack'
													/>
													{/* <button type="button" class="btn btn-primary ms-2" data-bs-toggle="modal" data-bs-target="#exampleModall" style={{width:"15rem"}}>
                          Transfer Racks
                        </button> */}
												</div>
											</div>
											<div className='row g-4'>
												{filteredData &&
													filteredData.map((item, index) => {
														if (item?.type === 'myrental') {
															if (!item?.forSell) {
																return (
																	<div className='col-md-3'>
																		<div
																			className='p-2'
																			style={{
																				backgroundColor: '#fff',
																				border: ' 1px solid rgb(243, 239, 239)',
																				borderRadius: '10px',
																			}}
																		>
																			<div
																				className='order'
																				style={{
																					display: 'flex',
																					justifyContent: 'space-between',
																				}}
																			>
																				<div
																					className=''
																					data-bs-toggle='modal'
																					data-bs-target='#exampleModal2'
																					onClick={() => setRackId(item?._id)}
																				>
																					<h4>{item?.name}</h4>
																				</div>

																				<div style={{ zIndex: '999' }}></div>
																			</div>
																			<div className='d-flex justify-content-between pt-3'>
																				<p>{item?.state}</p>
																				{item?.state === 'empty' ? (
																					<div
																						className=''
																						style={{
																							background: '#FEECDC',
																							width: '30px',
																							height: '30px',
																							lineHeight: '30px',
																							borderRadius: '50%',
																							justifyContent: 'center',
																							alignItems: 'center',
																							display: 'flex',
																						}}
																					>
																						<AiFillCloseCircle
																							size={20}
																							color='red'
																						/>
																					</div>
																				) : (
																					<div
																						className=''
																						style={{
																							background: '#FEECDC',
																							width: '30px',
																							height: '30px',
																							lineHeight: '30px',
																							borderRadius: '50%',
																							justifyContent: 'center',
																							alignItems: 'center',
																							display: 'flex',
																						}}
																					>
																						<i
																							class='fa-solid fa-check'
																							style={{
																								fontSize: '18px',
																								color: ' #ffd700',
																							}}
																						></i>
																					</div>
																				)}
																			</div>
																			<div>
																				{item?.state === 'empty' ? (
																					<button
																						onClick={() =>
																							navigate(
																								`/viewProduct/${item?.product}`
																							)
																						}
																						class='btn  w-100 text-right btn-primary'
																						disabled
																					>
																						View Product
																					</button>
																				) : (
																					<button
																						onClick={() =>
																							navigate(
																								`/viewProduct/${item?.product}`
																							)
																						}
																						class='btn  w-100 text-right btn-primary'
																					>
																						View Product
																					</button>
																				)}
																			</div>
																		</div>

																		{/* My rental Modal */}

																		<div
																			class='modal fade'
																			id='exampleModal2'
																			tabindex='-1'
																			aria-labelledby='exampleModalLabel'
																			aria-hidden='true'
																		>
																			<div class='modal-dialog w-100'>
																				<div class='modal-content w-100'>
																					<div class='modal-body w-100'>
																						<form
																							onSubmit={(e) => {
																								e.preventDefault();
																								updateSend(e.target);
																							}}
																						>
																							<div className='row  pt-4  align-items-center'>
																								<div className='col-md-2'>
																									<label
																										for='inputPassword6'
																										className='form-label'
																									>
																										Width (ft)
																									</label>
																								</div>
																								<div className='col-md-10'>
																									<input
																										className='form-control'
																										name='width'
																										placeholder='Enter Size'
																									/>
																								</div>
																							</div>
																							<div className='row  pt-4  align-items-center'>
																								<div className='col-md-2'>
																									<label
																										for='inputPassword6'
																										className='form-label'
																									>
																										Length (ft)
																									</label>
																								</div>
																								<div className='col-md-10'>
																									<input
																										className='form-control'
																										name='height'
																										placeholder='Enter Size'
																									/>
																								</div>
																							</div>
																							<div className='row  pt-4  align-items-center'>
																								<div className='col-md-2'>
																									<label
																										for='inputPassword6'
																										className='form-label'
																									>
																										Type
																									</label>
																								</div>
																								<div className='col-md-10'>
																									<select
																										class='form-control-input-2 p-1'
																										aria-label='Default select example'
																										name='type'
																									>
																										<option
																											value=''
																											selected
																											disabled
																										>
																											---Select---
																										</option>
																										<option value='owned'>
																											Owned
																										</option>
																										<option value='myrental'>
																											My Rent
																										</option>
																										{/* <option value="onrental">On Rental</option> */}
																									</select>
																								</div>
																							</div>
																							<div className='row  pt-4  align-items-center'>
																								<div className='col-md-2'>
																									<label
																										for='inputPassword6'
																										className='form-label'
																									>
																										State
																									</label>
																								</div>
																								<div className='col-md-10'>
																									<select
																										class='form-control-input-2'
																										name='state'
																									>
																										<option
																											value=''
																											selected
																											disabled
																										>
																											---Select---
																										</option>
																										<option value='occupied'>
																											Occupied
																										</option>
																										<option value='empty'>
																											Empty
																										</option>
																									</select>
																								</div>
																							</div>
																							<div className='row'>
																								<div className='col-lg-6 col-md-12 py-3'>
																									<button
																										type='button'
																										class='cancel-btn btn-lg'
																									>
																										Cancel
																									</button>
																								</div>
																								<div className='col-lg-6 col-md-12 py-3'>
																									<button
																										type='submit'
																										class='add-last-btn btn-lg'
																									>
																										Save
																									</button>
																								</div>
																							</div>
																						</form>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																);
															}
														}
													})}
											</div>
										</div>

										{/* onrental data */}

										<div
											class='tab-pane fade '
											id='pills-onSide'
											role='tabpanel'
											aria-labelledby='pills-onSide-tab'
										>
											<h3 className='py-4'>
												{/* Total Number of Racks: {rack.rent} */}
												Total Number of Racks: {onRentalRacks?.length}
											</h3>
											<div className='row g-4'>
												{filteredData &&
													filteredData.map((item, index) => {
														if (item?.type === 'onrental') {
															if (!item?.forSell) {
																return (
																	<div className='col-md-3'>
																		<div
																			className='p-2'
																			style={{
																				backgroundColor: '#fff',
																				border: ' 1px solid rgb(243, 239, 239)',
																				borderRadius: '10px',
																			}}
																		>
																			<div
																				className='order'
																				style={{
																					display: 'flex',
																					justifyContent: 'space-between',
																				}}
																			>
																				<div
																					className=''
																					data-bs-toggle='modal'
																					data-bs-target='#exampleModal2'
																					onClick={() => setRackId(item?._id)}
																				>
																					<h4>{item?.name}</h4>
																				</div>
																				<div style={{ zIndex: '999' }}></div>
																			</div>
																			<div className='d-flex justify-content-between pt-3'>
																				<p>{item?.state}</p>
																				{item?.state === 'empty' ? (
																					<div
																						className=''
																						style={{
																							background: '#FEECDC',
																							width: '30px',
																							height: '30px',
																							lineHeight: '30px',
																							borderRadius: '50%',
																							justifyContent: 'center',
																							alignItems: 'center',
																							display: 'flex',
																						}}
																					>
																						<AiFillCloseCircle
																							size={20}
																							color='red'
																						/>
																					</div>
																				) : (
																					<div
																						className=''
																						style={{
																							background: '#FEECDC',
																							width: '30px',
																							height: '30px',
																							lineHeight: '30px',
																							borderRadius: '50%',
																							justifyContent: 'center',
																							alignItems: 'center',
																							display: 'flex',
																						}}
																					>
																						<i
																							class='fa-solid fa-check'
																							style={{
																								fontSize: '18px',
																								color: ' #ffd700',
																							}}
																						></i>
																					</div>
																				)}
																			</div>
																			<div>
																				{item?.state === 'empty' ? (
																					<button
																						onClick={() =>
																							navigate(
																								`/viewProduct/${item?.product}`
																							)
																						}
																						class='btn  w-100 text-right btn-primary'
																						disabled
																					>
																						View Product
																					</button>
																				) : (
																					<button
																						onClick={() =>
																							navigate(
																								`/viewProduct/${item?.product}`
																							)
																						}
																						class='btn  w-100 text-right btn-primary'
																					>
																						View Product
																					</button>
																				)}
																			</div>
																		</div>

																		{/* onretal Modal */}
																		<div
																			class='modal fade'
																			id='exampleModal2'
																			tabindex='-1'
																			aria-labelledby='exampleModalLabel'
																			aria-hidden='true'
																		>
																			<div class='modal-dialog w-100'>
																				<div class='modal-content w-100'>
																					<div class='modal-body w-100'>
																						<form
																							onSubmit={(e) => {
																								e.preventDefault();
																								updateSend(e.target);
																							}}
																						>
																							<div className='row  pt-4  align-items-center'>
																								<div className='col-md-2'>
																									<label
																										for='inputPassword6'
																										className='form-label'
																									>
																										Width (ft)
																									</label>
																								</div>
																								<div className='col-md-10'>
																									<input
																										className='form-control'
																										name='width'
																										placeholder='Enter Size'
																									/>
																								</div>
																							</div>
																							<div className='row  pt-4  align-items-center'>
																								<div className='col-md-2'>
																									<label
																										for='inputPassword6'
																										className='form-label'
																									>
																										Length (ft)
																									</label>
																								</div>
																								<div className='col-md-10'>
																									<input
																										className='form-control'
																										name='height'
																										placeholder='Enter Size'
																									/>
																								</div>
																							</div>
																							<div className='row  pt-4  align-items-center'>
																								<div className='col-md-2'>
																									<label
																										for='inputPassword6'
																										className='form-label'
																									>
																										Type
																									</label>
																								</div>
																								<div className='col-md-10'>
																									<select
																										class='form-control-input-2 p-1'
																										aria-label='Default select example'
																										name='type'
																									>
																										<option
																											value=''
																											selected
																											disabled
																										>
																											---Select---
																										</option>
																										<option value='owned'>
																											Owned
																										</option>
																										<option value='myrental'>
																											My Rental
																										</option>
																										{/* <option value="onrental" >On Rental</option> */}
																									</select>
																								</div>
																							</div>
																							<div className='row  pt-4  align-items-center'>
																								<div className='col-md-2'>
																									<label
																										for='inputPassword6'
																										className='form-label'
																									>
																										State
																									</label>
																								</div>
																								<div className='col-md-10'>
																									<select
																										class='form-control-input-2'
																										name='state'
																									>
																										<option
																											value=''
																											selected
																											disabled
																										>
																											---Select---
																										</option>
																										<option value='occupied'>
																											Occupied
																										</option>
																										<option value='empty'>
																											Empty
																										</option>
																									</select>
																								</div>
																							</div>
																							<div className='row'>
																								<div className='col-lg-6 col-md-12 py-3'>
																									<button
																										type='button'
																										class='cancel-btn btn-lg'
																									>
																										Cancel
																									</button>
																								</div>
																								<div className='col-lg-6 col-md-12 py-3'>
																									<button
																										type='submit'
																										class='add-last-btn btn-lg'
																									>
																										Save
																									</button>
																								</div>
																							</div>
																						</form>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																);
															}
														}
													})}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</header>
			</div>
		</>
	);
}

export default withAuth(ERacks);
