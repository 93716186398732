import React, { useEffect, useState } from 'react';
import ApiUrl from '../BaseUrl';
import axios from 'axios';
import withAuth from '../../../hooks/withAuth';
function OwnSubAmb() {
	const [Ambassador, setAmbassador] = useState();

	return (
		<>
			<div className='container'>
				<h3 className='m-2'>Our Sub Quality Controller</h3>
				<div className='row'>
					{Ambassador &&
						Ambassador?.map((i) => {
							return (
								<div className='col-md-6 mt-3'>
									<div className='amd_data'>
										<div>
											<img
												src={`${ApiUrl}/images/${i?.profileImg}`}
												height={'130px'}
												alt='Amb_img'
											/>
										</div>
										<div style={{ fontSize: '0.9rem' }}>
											<b>ID :</b> {i._id}
											<br />
											<b>Name :</b> {i.name}
											<br />
											<b>Office Address :</b> {i.officeAddress}
											<br />
											<b>Contact No. :</b> {i.countryCode} {i.contactNumber}
											<br />
											<b>City :</b> {i.city}
											<br />
											<b>Country :</b> {i.country}
											<br />
										</div>
									</div>
								</div>
							);
						})}
				</div>
			</div>
		</>
	);
}

export default withAuth(OwnSubAmb);
