// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import { toast, ToastContainer } from 'react-toastify';
// import { useNavigate } from 'react-router-dom';
// import ApiUrl from '../Pages/BaseUrl';
// import axios from 'axios';
// import { QClogin } from '../Pages/ApiEndpoints';

// function Login() {
// 	const navigation = useNavigate('');

// 	const [username, setUsername] = useState('');

// 	const [password, setPassword] = useState('');

// 	const handleSubmit = async (values) => {
// 		if (values?.email?.value?.length === 0) {
// 			toast('Please Enter a email');
// 		} else if (values?.password?.value?.length === 0) {
// 			toast('Please Enter a password');
// 		} else {
// 			const params = {
// 				email: username,
// 				password: password,
// 			};

// 			// print form data

// 			console.log('params', params);

// 			console.log(QClogin);
// 			const data = await fetch(QClogin, {
// 				method: 'POST',
// 				headers: {
// 					'Content-Type': 'application/json',
// 				},
// 				body: JSON.stringify(params),
// 			});
// 			const response = await data.json();
// 			console.log('response', response);

// 			///store token locally
// 			if (data.ok) {
// 				console.log('response', response);
// 				localStorage.setItem('token', response?.token);
// 				localStorage.setItem('qualityContoller-user', JSON.stringify(response));
// 				localStorage.setItem('ambassadorID', response?._id);
// 				toast.success('Login Successful');
// 				setPassword('');
// 				setUsername('');
// 				navigation('/dashboard');
// 			} else {
// 				console.log('responseaksjdhakjsdhladkjlakdj');

// 				toast.error('Invalid Credentials');
// 			}

// 			// axios
// 			// 	.post(`${ApiUrl}/embassador/login`, params)

// 			// 	.then((res) => {
// 			// 		console.log(res.data);

// 			// 		if (res.data.status === 'fail') {
// 			// 			toast.error('Invalid Email or Password');
// 			// 		} else if (res.data.status === 'success') {
// 			// 			toast('Ambassador Login successfully!');
// 			// 			localStorage.setItem('ambassadorID', res.data.data._id);
// 			// 			localStorage.setItem('country', res.data.data.country);
// 			// 			localStorage.setItem('packageID', res.data.data.package);
// 			// 			navigation('/dashboard');
// 			// 		}
// 			// 	})
// 			// 	.catch((error) => {
// 			// 		toast.error('Invalid Email or Password');
// 			// 	});
// 		}
// 	};
// 	return (
// 		<>
// 			<div className='container'>
// 				<ToastContainer />
// 				<div className='row'>
// 					<div className='col-md-6'>
// 						<div className='login3'>
// 							<img
// 								src={require('../../assets/images/jack.jpeg')}
// 								style={{
// 									width: '100%',
// 									height: '100%',
// 									width: 'auto',
// 									height: 'auto',
// 									borderTopLeftRadius: '10px',
// 									borderBottomLeftRadius: '10px',
// 								}}
// 							/>
// 						</div>
// 					</div>
// 					<div className='col-md-6'>
// 						<section className='login2'>
// 							<div className='login'>
// 								<h5 style={{ marginBottom: '20px' }}>Login</h5>
// 								<form
// 									onSubmit={(e) => {
// 										e.preventDefault();
// 										handleSubmit(e.target);
// 									}}
// 									className='form'
// 								>
// 									<label style={{ fontSize: '12px' }}>Email</label>
// 									<br />
// 									<input
// 										type='email'
// 										name='email'
// 										onChange={(e) => setUsername(e.target.value)}
// 										class='form-control no-focus'
// 										style={{
// 											width: '95%',
// 											marginBottom: '10px',
// 											backgroundColor: '#fff',
// 										}}
// 										placeholder='Enter Your Email'
// 									/>

// 									<label style={{ fontSize: '12px' }}>Password</label>
// 									<br />
// 									<input
// 										type='password'
// 										name='password'
// 										onChange={(e) => setPassword(e.target.value)}
// 										class='form-control no-focus'
// 										style={{
// 											width: '95%',
// 											marginBottom: '30px',
// 											backgroundColor: '#fff',
// 										}}
// 										placeholder='Enter your password'
// 									/>

// 									<button
// 										type='submit'
// 										class='btn btn-success'
// 										style={{
// 											fontSize: '13px',
// 											width: '95%',
// 											backgroundColor: '#ffd700',
// 											borderColor: '#ffd700',
// 											marginTop: '-20px',
// 											color: '#000',
// 										}}
// 									>
// 										Login
// 									</button>
// 									<hr style={{ marginTop: '27px', width: '95%' }} />

// 									<a
// 										href='#'
// 										class='link btn btn-primary d-block text-center'
// 									>
// 										<i
// 											class='fa-brands fa-facebook-f'
// 											style={{ marginRight: '8px' }}
// 										></i>{' '}
// 										login with Facebook
// 									</a>

// 									<a
// 										href='#'
// 										class='link btn btn-danger d-block text-center'
// 									>
// 										<i
// 											class='fa-brands fa-google'
// 											style={{ marginRight: '8px' }}
// 										></i>{' '}
// 										login with Google
// 									</a>

// 									<a
// 										href='#'
// 										className='forgot-password'
// 									>
// 										Forgot your password
// 									</a>
// 									<br />

// 									<a
// 										href='#'
// 										className='forgot-password'
// 									>
// 										Creat Account
// 									</a>
// 								</form>
// 							</div>
// 						</section>
// 					</div>
// 				</div>
// 			</div>
// 		</>
// 	);
// }

// export default Login;




import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { MdOutlineCompare } from "react-icons/md";
import { TbArrowAutofitRight } from "react-icons/tb";
import 'react-toastify/dist/ReactToastify.css';

import StyledInput from '../../Input/Style'; 
import './Login.css'; 
import { QClogin } from '../Pages/ApiEndpoints'; 

const schema = yup.object().shape({
  email: yup.string().required('Email is required').email('Invalid email address'),
  password: yup.string().required('Password is required'),
});

function Login() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      const response = await fetch(QClogin, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        console.log('Login successful');
        const seller = await response.json();
        localStorage.setItem('token', seller?.token);
        localStorage.setItem('qualityContoller-user', JSON.stringify(seller));
        localStorage.setItem('ambassadorID', seller?._id);
        toast.success('Login Successful');
        reset();
        navigate('/dashboard');
      } else {
        console.error('Login failed');
        toast.error('Invalid Credentials');
      }
    } catch (error) {
      console.error('Error during login:', error);
    }
  };

  return (
    <>
      <div className="container">
        <ToastContainer />
        <div className="row">
          <div className="col-md-6">
            <div className="login3">
              <img
                src='login3.avif'
                // src='login7.jpg'
                alt="Seller Login Illustration"
                style={{
                  width: '100%',
                  height: '100%',
                  borderTopLeftRadius: '10px',
                  borderBottomLeftRadius: '10px',
                }}
              />
            </div>
          </div>
          <div className="col-md-6">
            <section className="login2">
              <form className="login" onSubmit={handleSubmit(onSubmit)}>
                <h1>Q</h1>
                <h5>Welcome QualityContoller,</h5>
                <p>A few steps to log in</p>
                <div className="form">
                  <StyledInput
                    type="email"
                    id="email"
                    placeholderText=" Email"
                    Icon={TbArrowAutofitRight}
                    register={register('email')}
                    error={errors.email}
                  />
                  <StyledInput
                    type="password"
                    id="password"
                    placeholderText="Password"
                    Icon={MdOutlineCompare}
                    register={register('password')}
                    error={errors.password}
                  />
                  <button className="btn btn-success" type="submit">
                    Login
                  </button>
                </div>
                <hr style={{ marginTop: '27px', width: '95%' }} />
               <div className='social'>
			   
			    <Link to= "/" className="link btn btn-primary d-block text-center">
                  <i className="fa-brands fa-facebook-f" style={{ marginRight: '8px' }}></i> login with Facebook
                </Link>
                <Link to ="/" className="link btn btn-danger d-block text-center">
                  <i className="fa-brands fa-google" style={{ marginRight: '8px' }}></i> login with Google
                </Link>
				</div>
				<div className='action'>

                <Link to="/" className="forgot-password">
                  Forgot your password
                </Link>
               
                <a to="/" className="forgot-password">
                  Create Account
                </a>
				</div>
              </form>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
