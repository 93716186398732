export const endpoint = 'http://localhost:3000';
// export const endpoint = 'http://165.22.215.169:80';

///qualityController Login
export const QClogin = `${endpoint}/qualityControllers/login`;

///Sellers APi
export const getReqSellers = `${endpoint}/sellers/`;



////Warehouse Api
export const getWareHouse = `${endpoint}/qualityControllers/getwarehouse`;
export const withdrawMoney = `${endpoint}/qualityControllers/withdraw`;
export const getAllWithdrawl = `${endpoint}/qualityControllers/withdraws`;
export const getAllTopUps = `${endpoint}/qualityControllers/topups`;
export const createTopUp = `${endpoint}/qualityControllers/topup`;
export const getAlltransfers = `${endpoint}/qualityControllers/transfer-payment`;
export const createRack = `${endpoint}/qualityControllers/addRack`;
export const getAllRacks = `${endpoint}/qualityControllers/getracks`;
export const allwarehouses = `${endpoint}/qualityControllers/getwarehouse`;



export const changePassword=`${endpoint}/qualityControllers/updatepasswordchangerequest`


export const addWareHouse = `${endpoint}/qualityControllers/addwarehouse`;
export const delWareHouse = `${endpoint}/qualityControllers/deletewarehouse`;
export const updateWarehouse = `${endpoint}/qualityControllers/updatewarehouse`;

// sellers api end points
export const getAllSellers = `${endpoint}/qualityControllers/getsellers`;
////Store Api
export const getStore = `${endpoint}/qualityControllers/getstore`;
export const addStore = `${endpoint}/qualityControllers/addstore`;

// get warehouses 
export const getWarehouses = `${endpoint}/qualityControllers/getwarehouse`;
// export const delWareHouse = `${endpoint}/qualityControllers/deletewarehouse`;
// export const updateWarehouse = `${endpoint}/qualityControllers/updatewarehouse`;



